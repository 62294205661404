export default class FormatService {
    public static formatDate(value: Date | string): string {
        let dt = new Date(value);
        let month = '' + (dt.getMonth() + 1);
        let day = '' + dt.getDate();
        const year = dt.getFullYear();

        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;

        return [month, day, year].join('/');
    }


    public static formatString(str: string, ...args: any[]): string {
        return str.replace(/{(\d+)}/g, (match, index) => args[index] || '');
    }
}
import React from "react";
import Config from "./Assets/Config";
import AuthProvider from "./Services/AuthProvider";
import { WesContext } from './Contexts/WesContext';
import WesModal from "./Components/WesModal";
import WesButton from "./Components/WesButton";
import DataService from "./Services/DataService";
import { Subscription } from "rxjs/internal/Subscription";
import { Col, Container, Row } from "react-bootstrap";

enum State {
    Expiring,
    Expired,
    Good
}

interface IState {
    status: State;
}

export default class AutoSignOut extends React.Component<any, IState> {
    static contextType = WesContext;
    res = (key: string, ...val: any[]) => this.context.resources.get(key, val);
    state: IState = { status: State.Good };
    warnTimerId: number | null = null;
    signOutTimerId: number | null = null;
    sub: Subscription | null = null;

    componentDidMount() {
        const auth = AuthProvider.getAuth();
        if (auth !== null) 
            this.startWarningTimer();

        this.sub = this.context.authChanged.subscribe((profile: any) => {
            if (profile === null) {
                this.stopTimers();
            }
            else {
                this.startWarningTimer();
            }
        });
    }

    componentWillUnmount() {
        this.sub?.unsubscribe();
    }

    render() {
        if (this.state.status === State.Good || this.state.status === State.Expired)
            return (<></>);

        return (
            <WesModal
                title={this.res("Warning")}
                show={true}>
                <Container>
                    <Row>
                        <h1 style={{ textAlign: "center" }}>{this.res("YouWillBeSignedOut")}</h1>
                    </Row>
                    <Row>
                        <Col>
                            <WesButton 
                                text={this.res("SignOutNow")}
                                type="secondary"
                                onClick={() => {
                                    this.context.signOut();
                                    window.location.href = this.context.timelineSignOutUrl;
                                }}
                            />
                        </Col>
                        <Col>
                            <WesButton 
                                text={this.res("Renew")}
                                onClick={() => this.renewToken()}
                            />
                        </Col>
                    </Row>
                </Container>
            </WesModal>
        )
    }

    startWarningTimer() : void {
        this.stopTimers();
        const oneMinMillis = 60000;
        const warnMillis = this.getMillisUntilExpires() - 60000;

        this.warnTimerId = window.setTimeout(() => {
            this.setState({ status: State.Expiring });

            this.signOutTimerId = window.setTimeout(() => {
                this.context.signOut();
                window.location.href = this.context.timelineSignOutUrl;
            }, oneMinMillis);
        }, warnMillis);
    }

    stopTimers() : void {
        if (this.warnTimerId !== null) {
            window.clearTimeout(this.warnTimerId);
            this.warnTimerId = null;
        }

        if (this.signOutTimerId !== null) {
            window.clearTimeout(this.signOutTimerId);
            this.signOutTimerId = null;
        }
    }

    async renewToken() : Promise<void> {
        try {
            const auth = await DataService.refreshToken();
            this.context.setAuth(auth);
            this.setState({ status: State.Good });
            this.startWarningTimer();
        }
        catch (e) {
            this.context.logging.error(e);
        }
    }

    getMillisUntilExpires() : number {
        const auth = AuthProvider.getAuth();
        const expiryDate = new Date(auth!.expires);
        //let date = new Date();
        //date.setSeconds(date.getSeconds() + 120);
        //const expiryDate = date;
        const diffMs = expiryDate.getTime() - new Date().getTime();
        return diffMs;
    }
}
export enum FileValidation {
    Success,
    FileTooBig,
    InvalidFileType
}

export default class Config {
    public static maxUploads: number = 20;
    public static credentialVerificationPrice: number = 30;
    public static sharePricePerAdd: number = 10;
    public static currency: string = 'USD';
    public static maxUploadFileSize: number = 10485760; // 10MB: 10,485,760 bytes
    public static validUploadMimeTypes: string[] = ['image/jpeg', 'image/jpg', 'image/pdf', 'application/pdf'];
    public static learnMoreUri: string = "https://wes.org/learn-more";
    public static isValidType(file: File): FileValidation {
        if (file.size > Config.maxUploadFileSize) 
            return FileValidation.FileTooBig;

        if (!Config.validUploadMimeTypes.includes(file.type))
            return FileValidation.InvalidFileType;

        return FileValidation.Success;
    }

    public static formatCurrency(value: number): string {
        return `${Config.currency} $${value.toFixed(2)}`;
    }

    public static calcSharePrice(recipients: number, docs: number): number {
        return Config.sharePricePerAdd * recipients * docs;
    }
}
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { IDocument, IRecipient } from '../../Services/DataServiceModels';
import DocumentList from './DocumentList';
import SubTotal from './SubTotal';
import styles from './Footer.module.scss';

export interface IProps {
    documents: IDocument[];
    recipients: IRecipient[];
}

export default class Footer extends React.Component<IProps> {

    render(): React.ReactNode {
        return (
            <div className={styles.container}>
                <Container>
                    <Row>
                        <Col>
                            <div className={styles.doclist}>
                                <DocumentList
                                    documents={this.props.documents}
                                />
                            </div>
                        </Col>
                        <Col>
                            <div className={styles.subtotal}>
                                <SubTotal 
                                    documents={this.props.documents.length}
                                    recipients={this.props.recipients.length}
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}
import React from "react";
import { WesContext } from "../../Contexts/WesContext";
import { IDocument } from "../../Services/DataServiceModels";
import styles from './DocumentList.module.scss';

export interface IProps {
    documents: IDocument[];
}

export default class DocumentList extends React.Component<IProps> {
    static contextType = WesContext;
    
    render() {
        const list = this.props.documents.map((doc, index) => {
            return (<li key={index}>{doc.name}</li>);
        });
        return (
            <div className={styles.container}>
                <strong>{this.context.resources.get("YoureSharing")}</strong>
                <ul>
                    {list}
                </ul>
            </div>
        );
    }
}
import React from "react";

export default class ExpiredPdf extends React.Component {
    render() {
        
        return (
            <div>
                <h2>You are not authorized to view the share document</h2>
            </div>
        );
    }
}
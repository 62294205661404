import { 
    IProfile,
    IAuthResult,
    AuthResultType
} from "./DataServiceModels";

export default class AuthProvider {

    private static _hasExpired(auth: IAuthResult | null): boolean {
        const expiryDate = new Date(auth!.expires);
        if (expiryDate < new Date()) {
            console.log('auth token expired');
            return true;
        }
        return false;
    }

    public static hasExpired() {
        const auth = AuthProvider.getAuth();
        return AuthProvider._hasExpired(auth);
    }

    public static clear() {
        localStorage.removeItem('auth');
    }

    public static setAuth(result: IAuthResult): void {
        localStorage.setItem('auth', JSON.stringify(result));
    }

    public static getAuth(): IAuthResult | null {
        const auth = localStorage.getItem('auth');
        if (auth != null) {
            const obj = JSON.parse(auth);
            if (AuthProvider._hasExpired(obj)) {
                localStorage.removeItem('auth');
                return null;
            }
            return obj;
        }
        return null;
    }
}
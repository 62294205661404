import React from 'react';
import { WesContext } from '../Contexts/WesContext';
import WesButton from './WesButton';
import { ReactComponent as UploadIcon } from '../Assets/Images/upload.svg';
import styles from './UploadFile.module.scss';

export interface IProps {
    onFilesAdded: (files: FileList) => void;
}

const validTypes = ['image/jpeg', 'image/jpg', 'image/pdf', 'application/pdf'];

export default class UploadFile extends React.Component<IProps> {
    static contextType = WesContext;
    res = (key: string) => this.context.resources.get(key);
    private fileInput: React.RefObject<HTMLInputElement>;
    
    constructor(props: IProps) {
        super(props);
        this.fileInput = React.createRef<HTMLInputElement>();
    }

    // var holder = document.getElementById('holder');
    // console.dir(holder)
    
    // holder.ondragenter = function (e) { e.preventDefault(); this.className = 'nicenice lvl-over'; return false; };
    // holder.ondragleave = function () { this.className = 'nicenice'; return false; };
    // holder.ondragover = function (e) { e.preventDefault() }
    // holder.ondrop = function (e) {
    //   e.preventDefault();
    //   console.log("GOT DROP EVENT");
    //   alert("dropped here");
    //   return false;
    // };
    render(): JSX.Element {
        return (
            <div 
                className={styles.content}
                onDragOver={this.onDragOver}
                onDragEnter={this.onDragEnter}
                onDragLeave={this.onDragLeave}
                onDrop={this.onFileDrop}>
                
                <UploadIcon /><br />
                {this.res("DragFilesHere")}<br />
                {this.res("OR")}<br />

                <input
                    ref={this.fileInput}
                    onChange={this.onInputFileUpload}
                    type="file"
                    style={{ display: "none" }}
                    multiple={true}
                />
                <WesButton 
                    text={this.res("Browse")} 
                    type="secondary"
                    onClick={() => this.fileInput.current!.click()}
                />
            </div>
        );
    }

    onDragOver = (e: React.DragEvent<HTMLDivElement>) : void => {
        // TODO: div back to white
        e.preventDefault();
    }

    onDragEnter = (e: React.DragEvent<HTMLDivElement>) : void => {
        e.preventDefault();
        // TODO: turn div light blue?
    }

    onDragLeave = (e: React.DragEvent<HTMLDivElement>) : void => {
        e.preventDefault();
        // TODO: turn div light blue?
    }
    
    onInputFileUpload = (e: React.ChangeEvent<HTMLInputElement>) : void => {
        this.tryFireEvent(e.target?.files);
    }

    onFileDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        this.tryFireEvent(e.dataTransfer.files);
    }

    validateFile(e: File) : boolean {
        // 10MB: 10,485,760 bytes
        if (e.size > 10485760) {
            console.log('fail size', e);
            return false;
        }
        if (validTypes.indexOf(e.type) === -1) {
            // invalid
            console.log('fail type', e);
            return false;
        }
        return true;
    }

    tryFireEvent(e: FileList | null) {
        if (e == null)
            return;
        
        if (e.length > 0) {
            for (let i = 0; i < e.length; i++) {
                if (!this.validateFile(e[i])) {
                    // TODO: display error message
                    return;
                }
            }
            this.props.onFilesAdded(e);
        }
    }
}

import React from "react";
import LoadingOverlay from "react-loading-overlay";
import { WesContext } from "../Contexts/WesContext";
import DataService from "../Services/DataService";
import { AuthResultType } from "../Services/DataServiceModels";

export interface IProps {
    location: any;
    history: any;
}

interface IState {
    loading: boolean;
}

export default class Auth extends React.Component<IProps, IState> {
    static contextType = WesContext;
    res = (key: string) => this.context.resources.get(key);
    state: IState = { loading: false };
    
    async componentDidMount() {
        try {
            this.context.signOut(); // kill any existing auth
            this.setState({ loading: true });
            const token = this.props.location.search.substring(1); // trim ?
            const result = await DataService.authenticate(token);
            this.setState({ loading: false });

            if (result.type === AuthResultType.Success)
                this.context.setAuth(result);

            if (result.hasSubscription)
                this.props.history.replace('/selectapp');
            else
                this.props.history.replace('/subscribe');

        }
        catch (ex) {
            this.context.logging.error(ex);
        }
    }

    render() {
        return (
            <LoadingOverlay active={this.state.loading}>
                <div style={{ height: '100%' }}></div>
            </LoadingOverlay>
        )
    }
}

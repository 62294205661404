import React from 'react';
import styles from './WesButton.module.scss';

type ButtonType = "primary" | "secondary";
interface IProps {
    type?: ButtonType;
    disabled?: boolean;
    text: string;
    show?: boolean;
    onClick: (call: void) => void;
}


export default class WesButton extends React.Component<IProps> {
    render() {
        if (this.props.show === false)
            return (<></>);

        const type = this.props.type || "primary";
        let className = styles.btn + " ";
        className += type === "secondary" ? styles.btnsecondary : styles.btnprimary;
        if (this.props.disabled === true)
            className += " " + styles.btndisabled;

        return (
            <a href="#" className={className} onClick={this.onClick}>
                <span className={styles.span}>{ this.props.text }</span>
            </a>
        );
    }

    onClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) : void => {
        e.preventDefault();
        if (this.props.disabled !== true) {
            this.props.onClick();
        }
    }
}
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import LoadingOverlay from 'react-loading-overlay';
import styles from './WesDataList.module.scss';

export interface IColumn {
    title: string;
    render: (item: any) => React.ReactNode;
}

export interface IProps {
    loading: boolean;
    columns: IColumn[];
    data: any[];
    noDataText?: string;
    renderExpandRow?: (item: any) => React.ReactNode;
}

export interface IState {
    expandedIndexes: number[];
}

export default class WesDataList extends React.Component<IProps, IState> {
    state: IState = {
        expandedIndexes: []
    };

    render() {
        const { columns, renderExpandRow, data, noDataText, loading } = this.props;

        if (data.length === 0) {
            if (noDataText == null)
                return (<></>);

            return (<strong>{noDataText}</strong>);
        }

        const header = columns.map((item, index) => {
            const key = `title-${index}`;
            return (<Col key={key} className={styles.header}>{item.title}</Col>);
        });

        const list = this.renderList();

        return (
            <LoadingOverlay active={loading}>
                <Container className={styles.container}>
                    <Row>
                        {renderExpandRow && <div className={styles.expanderColumn}>&nbsp;</div>}
                        {header}
                    </Row>
                    {list}
                </Container>
            </LoadingOverlay>
        );
    }

    renderList() {
        const { data, renderExpandRow, columns } = this.props;

        return data.map((item, dataIndex) => {
            const cols = columns.map((dataItem, colIndex) => {
                const key = `row-${dataIndex}-col-${colIndex}`;
                return (
                    <Col key={key} className={styles.dataItem}>
                        {dataItem.render(item)}
                    </Col>
                );
            });

            return (
                <Row key={`row-${dataIndex}`} className={styles.rowStyle}>
                    {renderExpandRow && <div className={styles.expanderColumn} onClick={() => this.toggleExpand(dataIndex)}>{this.getExpander(dataIndex)}</div>}
                    {cols}
                    {renderExpandRow && this.renderExpand(item, dataIndex)}
                </Row>
            );
        });
    }

    renderExpand(item: any, index: number) : React.ReactNode {
        const re = this.props.renderExpandRow;

        if (re != null && this.isExpanded(index)) {
            return (
                <div className={styles.expandSeperator}>
                    {re(item)}
                </div>
            );
        }
        
        return (<></>);
    }

    getExpander(index: number) : React.ReactNode {
        const cls = this.isExpanded(index) ? styles.collapse : styles.expand;
        return (<div className={cls}></div>);
    }

    isExpanded(index: number) : boolean {
        return this.state.expandedIndexes.indexOf(index) > -1;
    }

    toggleExpand(index: number) {
        let i = this.state.expandedIndexes;
        if (this.isExpanded(index)) {
            const start = i.indexOf(index);
            i.splice(start, 1);
        }
        else {            
            i.push(index);            
        }
        this.setState({ expandedIndexes: i });
    }
}
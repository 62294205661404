import React from 'react';
import WesButton from '../Components/WesButton';
import { WesContext } from '../Contexts/WesContext';
import DataService from '../Services/DataService';
import Config from '../Assets/Config';
import LoadingOverlay from 'react-loading-overlay';
import { Col, Row } from 'react-bootstrap';
import styles from './ExistingSubscriber.module.scss';


export interface IProps {
    history: any;
}

interface IState {    
    loading: boolean;
}

export default class ExistingSubscriber extends React.Component<IProps, IState> {
    static contextType = WesContext;
    res = (key: string) => this.context.resources.get(key);
    state: IState = {      
        loading: false
    };
    
    render() { 
        return (
            <LoadingOverlay active={this.state.loading}>
                <Row style={{ marginLeft: "150px" }}>
                <div className={styles.container}>
                    <div className={styles.descriptionContainer}>
                        <div className={styles.contentContainer }>
                            <h1>{this.res("GoToMyAccount")}</h1>
                                <p>{this.res("GoToMyAccountDescription")}</p>                                
                            </div>
                            <p style={{textAlign:"center"}}>
                            <WesButton
                                text={this.res("GoToMyAccount")}
                                type="secondary"
                                    onClick={() => window.location.href = this.context.timelineUrl}
                            /></p>
                     </div>                       
                </div>                
                 <div className={styles.container}>
                    <div className={styles.descriptionContainer}>
                        <div className={styles.contentContainer }>
                            <h1>{this.res("GoToMyGlobalRecords")}</h1>
                            <p>{this.res("GoToMyGlobalRecordsDescription")}</p>                        
                               
                         </div>
                           <p style={{textAlign:"center"}}>
                            <WesButton
                                text={this.res("GoToMyGlobalRecords")}
                                    type="secondary"
                                    onClick={() => window.location.href = window.location.origin}
                            /></p>
                     </div>
                  </div> 
                  </Row>
            </LoadingOverlay>
        );
    }
}

import React from 'react';
import WesButton from '../Components/WesButton';
import CredentialForm from './Components/CredentialForm';
import RequiredDocsInfo from './Components/RequiredDocsInfo';
import ReviewCredential from './Components/ReviewCredential';
import OrderSummary from './Components/OrderSummary';
import { ICredential } from '../Services/DataServiceModels';
import { WesContext } from '../Contexts/WesContext';
import WesWizardModal from '../Components/WesWizardModal';
import DataService from '../Services/DataService';
import Config from '../Assets/Config';


export interface IState {
    step: number;
    showModal: boolean;
    cred: ICredential | null;
    isHst: boolean;
    isFree: boolean;
}

export interface IProps {
    onSubmit: () => void;
}

export default class AddCredential extends React.Component<IProps, IState> {
    static contextType = WesContext;
    res = (key: string, ...val: any[]) => this.context.resources.get(key, val);
    submitForm: any;

    state: IState = {
        step: 1,
        showModal: false,
        cred: null,
        isHst: false,
        isFree: false
    };

    render() {
        const values = this.getStepValues();

        return (
            <>
                <WesButton
                    text={this.res("VerifyNewCredential")}
                    onClick={() => this.setState({ showModal: true })}
                />

                <WesWizardModal
                    step={this.state.step}
                    steps={[
                        this.res("AddCredential"),
                        this.res("RequiredDocuments"),
                        this.res("Review"),
                        this.res("Payment")
                    ]}
                    nextText={values.nextText}
                    backText={values.backText}
                    nextEnabled={true}
                    onClose={() => this.setState({ showModal: false })}
                    onBack={() => this.setState({ step: this.state.step - 1 })}
                    onNext={this.onNext}
                    show={this.state.showModal}
                    title={this.res("VerifyNewCredential")}>
                    {values.component}
                </WesWizardModal>
            </>
        );
    }

    onNext = () => {
        switch (this.state.step) {
            case 1:
                this.submitForm();
                break;
            case 2:
                this.setState({ step: 3 });
                break;
            case 3:
                this.setState({ step: 4 });
                break;

            case 4:
                if (this.state.isHst) {
                    var newCred = this.state.cred;
                    newCred!.isHst = this.state.isHst;

                    this.setState({ cred: newCred })
                }

                if (this.state.isFree) {
                    var newCred = this.state.cred;
                    newCred!.isFree = this.state.isFree;

                    this.setState({ cred: newCred })
                }

                // set the name using profile context
                // bandaid solution for now
                var c = this.state.cred!;
                c.firstName = this.context.user.firstName;
                c.lastName = this.context.user.lastName;
                this.setState({ cred: c });

                DataService
                    .processNewCredential(this.state.cred!)
                    .then(process => {
                        if (this.state.isFree)
                            process.forwardUrl = window.location.origin + process.forwardUrl;

                        window.location.href = process.forwardUrl
                    }
                    );


                break;
        }
    }

    onCredentialSubmit = (e: ICredential) => {
        this.setState({
            cred: e,
            step: 2
        });
    }

    onClose = () => {
        this.setState({
            step: 1,
            showModal: false
        });
    }

    handleOrdSummaryCheckbox = (e: any) => {
        if (e != null) {
            this.setState({ isHst: e?.target?.checked });
        }
    }


    async componentDidMount() {
        try {
            let isFree = await DataService.checkCredFree();
            this.setState({ isFree });
        }
        catch (ex) {
            this.context.logging.error(ex);
        }
    }


    getStepValues(): any {
        switch (this.state.step) {
            case 4: {
                let bt = this.res("Pay");
                let price = Config.credentialVerificationPrice;
                if (this.state.isFree) {
                    bt = "Finish";
                    price = 0;
                }
                return {
                    backText: this.res("Back"),
                    nextText: bt,
                    component: <OrderSummary isHst={this.state.isHst} handleIsCanCheckbox={this.handleOrdSummaryCheckbox} price={price} /> // price should be calculated here probably
                };
            }


            case 3: {return {
                backText: this.res("RequiredDocuments"),
                nextText: this.res("Payment"),
                component: <ReviewCredential credential={this.state.cred!} />
            };
                }
            case 2: return {
                backText: this.res("Edit"),
                nextText: this.res("Review"),
                component: <RequiredDocsInfo />
            };

            default:
            case 1: return {
                backText: null,
                nextText: this.res("RequiredDocuments"),
                component: <CredentialForm
                    key={this.state.cred?.firstName || "new"}
                    credential={this.state.cred}
                    onCredentialChange={this.onCredentialSubmit}
                    bindSubmit={e => this.submitForm = e}
                />
            }
        }
    }
}
import React from 'react';
import { FormCheck } from 'react-bootstrap';
import WesButton from '../Components/WesButton';
import { WesContext } from '../Contexts/WesContext';
import DataService from '../Services/DataService';
import Config from '../Assets/Config';
import LoadingOverlay from 'react-loading-overlay';
import { Col, Row } from 'react-bootstrap';

export interface IProps {
    history: any;
}

interface IState {
    dontShowAgain: boolean;
    loading: boolean;
    isHst: boolean;
}

export default class AddSubscription extends React.Component<IProps, IState> {
    static contextType = WesContext;
    res = (key: string) => this.context.resources.get(key);
    resNested = (key: string) => this.context.resources.getNested(key);

    state: IState = {
        dontShowAgain: false,
        loading: false,
        isHst: false
    };

    render() {
        const icap: boolean = this.context.user.isICAP;
        //const icap = false;
        let cssStyle = {};
        let benefits: string[] = [];
        let priceHeader = null;

        let audienceType = this.context.user.audienceType;
        const isDraft = audienceType === 2;
        let learnMoreUrl = "";
        switch (audienceType) {
            case 0: {
                //ICAP
                // content for ICAP users
                cssStyle = { textDecoration: "line-through" };

                benefits = [
                    this.resNested("NewSubscription:SubscriptionBenefit1"),
                    this.resNested("NewSubscription:SubscriptionBenefit2")
                ];

                priceHeader = <h1><span style={cssStyle}>$125/ 2 years </span>No cost to you!</h1>;
                // TODO: MOVE THIS URL TO SOMEWHERE ELSE
                learnMoreUrl = "https://www.wes.org/global-records-service-icap-beta/";
                break;
            }
            case 1: {
                // NON ICAP
                benefits = [
                    this.resNested("NewSubscription:SubscriptionBenefitNonIcap1"),
                    this.resNested("NewSubscription:SubscriptionBenefitNonIcap2")
                ];

                /*cssStyle = { textDecoration: "" };*/
                cssStyle = { textDecoration: "line-through" };
                priceHeader = <h1>
                    <span>Limited time offer: </span>
                    <br />
                    <span style={cssStyle}>$125/ 2 years </span>
                    $99/2 years for you!</h1>;
                learnMoreUrl = "https://www.wes.org/global-records-service-beta-basic/";
                break;
            }
            case 2: {
                //DRAFT
                priceHeader = <h1>$125/ 2 years, includes adding your first credential for free.​</h1>;
                benefits = [
                    this.resNested("NewSubscription:DraftBenefit1"),
                    this.resNested("NewSubscription:DraftBenefit2")
                ];
                learnMoreUrl = "https://www.wes.org/global-records-service-beta/";
            }
        }

        let benefitsList = benefits.map(b => { return <li>{b}</li> });
        // TODO: if ICAP, cross out payment and change URL
        return (
            <LoadingOverlay active={this.state.loading}>
                <Row>
                    <Col sm="5">
                        <div style={{ padding: "20px 10px" }}>
                            <h1>
                                {this.res("WesGlobalRecordsService")} </h1>
                            <p>{this.res("GrsDescription")} </p>

                        </div>
                        <Row>
                            <Col sm="5">
                                <WesButton
                                    text={this.res("LearnMore")}
                                    type="secondary"
                                    onClick={() => window.location.href = learnMoreUrl}
                                />
                            </Col>
                            <Col sm="3">
                                <WesButton
                                    text={this.res("GetStarted")}
                                    onClick={async () => {
                                        this.setState({ loading: true });
                                        try {
                                            if (icap) {
                                                await DataService.createIcapSubscription();
                                                const auth = await DataService.refreshToken();
                                                this.context.setAuth(auth);
                                                this.props.history.replace("/");
                                            }
                                            else {
                                                const gateway = await DataService.processSubscription({ isHst: this.state.isHst, isDraft: isDraft});
                                                window.location.href = gateway.forwardUrl;
                                            }
                                        }
                                        catch (e) {
                                            this.context.logging.error(e);
                                        }
                                    }}
                                />
                            </Col>
                        </Row>
                        <div style={{ padding: "20px 10px" }}>
                            <p>
                                <a href="#" onClick={async e => {
                                    e.preventDefault();
                                    if (this.state.dontShowAgain === true) {
                                        await DataService.declineGrs();
                                    }
                                    window.location.href = this.context.timelineUrl;
                                }}>
                                    <strong>{this.res("NoTakeMeToMyAccount")}</strong></a></p>
                            <FormCheck
                                label={this.res("DontShowMeAgain")}
                                onChange={e => this.setState({ dontShowAgain: e.target.checked })}
                                checked={this.state.dontShowAgain}
                            />

                            <div hidden={!this.context.user.isCanApp}>
                                <FormCheck
                                    label={this.res("IamInCanada")}
                                    checked={this.state.isHst}
                                    onChange={e => this.setState({ isHst: e.target.checked })} />
                            </div>
                        </div>
                    </Col>
                    <Col sm="1"></Col>
                    <Col sm="5">
                        <div style={{ padding: "20px 10px" }}>
                            {/*<h1><span style={cssStyle}>{price}</span> {contentHeader}</h1>*/}
                            {priceHeader}
                            <ul>
                                {benefitsList}
                            </ul>
                        </div>
                    </Col>
                </Row>
            </LoadingOverlay>
        );
    }
}
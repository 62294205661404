import React from 'react';
import { Card, Col, Container, Row, Stack } from 'react-bootstrap';
import { ReactComponent as PdfIcon } from "../../Assets/Images/pdf-icon.svg";
import { ReactComponent as JpgIcon } from "../../Assets/Images/jpg-icon.svg";
import { WesContext } from "../../Contexts/WesContext";
import styles from "./FileUploadList.module.scss";

export interface IProps {
    files: File[];
    onRemove: (index: number) => void;
}

export default class FileUploadList extends React.Component<IProps> {
    static contextType = WesContext;
    res = (key: string) => this.context.resources.get(key);

    render() {
        const rows = this.props.files.length === 0 
            ? (<></>) 
            : this.props.files.map((item, index) => {
                return (
                    <div key={index} style={{ margin: '5px' }}>
                        <Card>
                            <Card.Body>
                                <Container>
                                    <Row>
                                        <Col xs lg="2">{this.getFileIcon(item)}</Col>
                                        <Col>{item.name}</Col>
                                        <Col md='auto'>
                                            <Stack direction="horizontal" gap={2}>
                                                <a 
                                                    href="#" 
                                                    className={styles.actionLink}
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        this.onPreview(index);
                                                    }}>{this.res("Preview")}</a>
                                                <a 
                                                    href="#" 
                                                    className={styles.actionLink}
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        this.props.onRemove(index); 
                                                    }}>{this.res("Remove")}</a>
                                            </Stack>
                                        </Col>
                                    </Row>
                                </Container>
                            </Card.Body>
                        </Card>
                    </div>
                );
        });

        return (<>{rows}</>);
    }

    getFileIcon(item: File) : JSX.Element {
        const ft = item.name.toLowerCase();
        if (ft.endsWith(".pdf"))
            return (<PdfIcon />);

        if (ft.endsWith(".jpg"))
            return (<JpgIcon />);

        return (<></>);
    }

    onPreview(index: number) : void {
        const file = this.props.files[index];
        const url = URL.createObjectURL(file);
        window.open(url, "_blank");
    }
}
import React from "react";
import WesButton from "../Components/WesButton";
import DataService from "../Services/DataService";
import Footer from "./Components/Footer";
import WesWizardModal from "../Components/WesWizardModal";
import FormHeader from "../Components/FormHeader";
import Config from "../Assets/Config";
import { WesContext } from "../Contexts/WesContext";
import { IDocument, IRecipient } from "../Services/DataServiceModels";
import RecipientList from "./Components/RecipientList";
import LoadingOverlay from "react-loading-overlay";
import RecipientForm from "./Components/RecipientForm";
import OrderSummary from "../VerifyCredential/Components/OrderSummary";

export interface IProps {
    documents: IDocument[];
    onClose: () => void;
}

interface IState {
    edit: IRecipient | null;
    show: boolean;
    recipients: IRecipient[];
    loading: boolean;
    step: number;
    isHst: boolean;
}

export default class ShareDocuments extends React.Component<IProps, IState> {
    static contextType = WesContext;
    res = (key: string, ...val: any[]) => this.context.resources.get(key, val);

    state: IState = {
        edit: null,
        loading: false,
        show: false,
        recipients: [],
        step: 1,
        isHst: false
    };

    render() {
        return (
            <>
                <WesButton
                    text={this.res("ShareSelected")}
                    disabled={this.props.documents.length === 0}
                    onClick={() => this.setState({ show: !this.state.show })}
                />

                <WesWizardModal
                    step={this.state.step}
                    steps={[
                        this.res("AddRecipients"),
                        this.res("Review"),
                        this.res("Payment")
                    ]}
                    show={this.state.show}
                    nextText={this.getNextText()}
                    onNext={() => this.onNext()}
                    onBack={() => this.setState({ step: this.state.step - 1 })}
                    nextEnabled={this.canNext()}
                    onClose={this.onClose}
                    title={this.res("ShareDocuments")}>
                    <>
                        <LoadingOverlay active={this.state.loading}>
                            {this.getStep()}
                        </LoadingOverlay>
                    </>
                </WesWizardModal>
            </>
        );
    }

    getNextText(): string {
        switch (this.state.step) {
            case 1: return this.res("Review");
            case 2: return this.res("Payment");
            case 3: return this.res("Pay");
            default: return "";
        }
    }

    canNext(): boolean {
        if (this.state.step === 1)
            return this.state.recipients.length > 0;

        return true;
    }

    onNext = () => {
        const nextStep = this.state.step + 1;
        if (nextStep < 4) {
            if (this.state.recipients.length > 0)
                this.setState({ step: nextStep });
        }
        else {
            this.setState({ loading: true });
            DataService
                .processShare({
                    recipients: this.state.recipients,
                    documents: this.props.documents,
                    isHst: this.state.isHst
                })
                .then(process => {
                    window.location.href = process.forwardUrl;
                })
                .catch(err => {
                    this.context.logging.error(err);
                    this.setState({ loading: false });
                });


        }
    }

    onClose = () => {
        this.setState({
            show: false,
            recipients: [],
            step: 1
        });
        this.props.onClose();
    }

    handleOrdSummaryCheckbox = (e: any) => {
        if (e != null) {
            this.setState({ isHst: e?.target?.checked });
        }
    }

    getStep(): React.ReactNode {
        switch (this.state.step) {
            case 1: return this.getStep1();
            case 2: return this.getStep2();
            case 3: return this.getStep3();
            default: return (<></>);
        }
    }

    getStep1(): React.ReactNode {
        return (
            <>
                <FormHeader
                    title={this.res("AddRecipients")}
                    subtext={this.res(
                        "ShareDocumentsSubtext",
                        Config.sharePricePerAdd
                    )}
                />
                <RecipientList
                    recipients={this.state.recipients}
                    onEdit={e => this.setState({ edit: e })}
                    onDelete={x => this.handleDelete(x)}
                />
                <RecipientForm
                    edit={this.state.edit}
                    onSubmit={(e: IRecipient) => {
                        this.handleDelete(e); // remove any dupes first
                        this.state.recipients.push(e);
                        this.setState({
                            recipients: this.state.recipients,
                            edit: null
                        });
                    }}
                />
                <Footer
                    recipients={this.state.recipients}
                    documents={this.props.documents}
                />
            </>
        );
    }

    getStep2(): React.ReactNode {
        return (
            <>
                <FormHeader
                    title={this.res("AddRecipients")}
                    subtext={this.res(
                        "ShareDocumentsSubtext",
                        Config.sharePricePerAdd
                    )}
                />
                <RecipientList
                    recipients={this.state.recipients}
                />
                <Footer
                    recipients={this.state.recipients}
                    documents={this.props.documents}
                />
            </>
        );
    }

    getStep3(): React.ReactNode {

        // the subtotal foother here can use some work but it is working now
        return <>
            <OrderSummary handleIsCanCheckbox={this.handleOrdSummaryCheckbox} isHst={this.state.isHst} price={Config.calcSharePrice(this.state.recipients.length, this.props.documents.length)} />
        </>
    }

    handleDelete = (e: IRecipient): void => {
        let r = this.state.recipients;
        const index = r.findIndex(x => x.email === e.email);
        if (index > -1) {
            r.splice(index, 1);
            if (r.length === 0 && this.state.step === 2) {
                this.setState({ step: 1 });
            }

            this.setState({ recipients: r });
        }
    }

    async submitForPayment() {
        try {
            const process = await DataService.processShare({
                documents: this.props.documents,
                recipients: this.state.recipients,
                isHst: this.state.isHst
            });
            window.location.href = process.forwardUrl;
            //this.setState({ show: false });
        }
        catch (ex) {
            this.context.logging.error(ex);
        }
    }
}
import React from "react";
import FormCheck from "react-bootstrap/FormCheck";
import UploadFile from "../Components/UploadFile";
import { IPendingVerificationCredential } from "../Services/DataServiceModels";
import UploadInfo from "./Components/UploadInfo";
import FileUploadList from "./Components/FileUploadList";
import DataService from "../Services/DataService";
import { WesContext } from "../Contexts/WesContext";
import WesWizardModal from "../Components/WesWizardModal";
import FormHeader from "../Components/FormHeader";
import LoadingOverlay from "react-loading-overlay";

export interface IProps {
    credential: IPendingVerificationCredential;
    onComplete: (didUpload: boolean) => void;
    show: boolean;
}

interface IState {
    step: number;
    understood: boolean;
    files: File[];
    uploading: boolean;
}

export default class UploadDocument extends React.Component<IProps, IState> {
    static contextType = WesContext;
    res = (key: string) => this.context.resources.get(key);
    
    state: IState = { 
        step: 1,
        understood: false,
        uploading: false,
        files: []
    };

    render() {
        const el = (this.state.step === 1 ? this.renderStep1() : this.renderStep2());

        return (
            <WesWizardModal 
                steps={[ 
                    this.res("Requirements"), 
                    this.res("Documents")
                ]}
                step={this.state.step}
                show={this.props.show}
                title={this.res("DegreeCertificateUpload")}
                nextText={this.getNextText()}
                onBack={() => this.setState({ step: this.state.step - 1 })}
                onNext={() => this.handleNext()}
                nextEnabled={this.canNext()}
                onClose={this.onClose}>
                    {el}
            </WesWizardModal>
        )
    }

    getNextText() : string {
        if (this.state.step === 1)
            return this.res("Next");

        return this.res("Submit");
    }

    onClose = () => this.close(false);    

    close(complete: boolean) : void {
        this.setState({
            step: 1,
            understood: false,
            uploading: false,
            files: []
        });
        this.props.onComplete(complete);
    }

    renderStep1() : JSX.Element {
        return (<UploadInfo />);
    }

    renderStep2() : JSX.Element {
        return (
            <LoadingOverlay active={this.state.uploading}>
                <FormHeader 
                    title={this.res("UploadOnlyDegreeCertificates")}
                    subtitle={this.res("AcceptedFileTypes")}
                />
                <UploadFile
                    onFilesAdded={this.onFiles} 
                />
                <FileUploadList 
                    onRemove={this.onRemove}
                    files={this.state.files}
                />
                <div style={{ padding: "20px 10px" }}>
                    <span><strong>{this.res("Note")}:</strong>&nbsp;{this.res("PleasePreviewDocs")}</span>
                    <br /><br/>
                    <FormCheck 
                        label={this.res("IUnderstand")}
                        onChange={e => this.setState({ understood: e.target.checked })}
                        checked={this.state.understood}
                    />
                </div>
            </LoadingOverlay>
        );
    }

    canNext = (): boolean => {
        if (this.state.step === 1)
            return true;

        if (this.state.files.length > 0 && this.state.understood === true)
            return true;

        return false;
    }

    onRemove = (index: number) : void => {
        this.state.files.splice(index, 1);
        this.setState({ files: this.state.files });
    }

    onFiles = (e: FileList) : void => {
        for (let i = 0; i < e.length; i++) {
            this.state.files.push(e[i]);
        }
        this.setState({ files: this.state.files });
    }

    handleNext = () : void => {
        if (this.state.step === 1) {
            this.setState({ step: 2 });
        }
        else {
            this.setState({ uploading: true });
            DataService
                .uploadDocuments(
                    this.props.credential.id!, 
                    this.state.files
                )
                .then(() => {
                    this.close(true);
                })
                .catch(e => {
                    this.context.logging.error(e);
                    this.setState({ uploading: false });
                });
        }
    }
}
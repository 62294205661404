import React from "react";
import Config from "../Assets/Config";
import { WesContext } from "../Contexts/WesContext";

export default class NotAuthenticated extends React.Component {
    static contextType = WesContext

    getNoAuthUrl() {
        var origin = window.origin;

        if (origin.includes("grsdev")) {
            return "https://backupweb.wes.org/createaccount/login/sessionexpired";
        }
        else if (origin.includes("localhost")) {
            return "http://localhost/createaccount/login/sessionexpired";
        }
        else {
            // DEFAULT TO PROD
            return "https://applications.wes.org/createaccount/login/sessionexpired";
        }
    }

    render() {
        const contextUrl = this.context.timelineSignOutUrl
        const url = (!contextUrl || contextUrl.length === 0) ? this.getNoAuthUrl() : contextUrl;

        return (window.location.href = url);
        //return (<div>{this.state.noAuthUrl}</div>);
    }
}
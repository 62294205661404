import React from "react";
import { Subject } from "rxjs";
import { forEachChild } from "typescript";
import { default as resources } from '../Assets/Resources/Strings.json';
import AuthProvider from "../Services/AuthProvider";
import { AuthResultType, IAuthResult, IProfile } from "../Services/DataServiceModels";
import FormatService from "../Services/FormatService";


export interface ILogService {
    error(e: Error): void;
}

export interface IResourceProvider {
    get: (selector: string, ...val: any[]) => string;
    getNested: (selector: string, ...val: any[]) => string;
}

export type WesContextType = {
    init(): void;
    resources: IResourceProvider,
    logging: ILogService,
    authenticated: boolean,
    user?: IProfile | null,
    timelineUrl: string,
    timelineSignOutUrl: string,
    hasSubscription: boolean;
    setAuth(authResult: IAuthResult): void;
    authChanged: Subject<IProfile | null>;
    signOut(): void;
}

export const WesContext = React.createContext<WesContextType>({
    authChanged: new Subject<IProfile | null>(),

    resources: {
        get: (selector: string, ...val: any[]): string => {
            const value = String((resources as any)[selector]);
            if (value === null)
                console.error(`Key ${selector} not found in resources`);

            const result = FormatService.formatString(value, val);
            return result;
        },

        getNested: (selector: string, ...val: any[]): string => {
            var splitted: string[] = selector.split(":");

            var v = resources;
            for (let x of splitted) {

                var temp = (v as any)[x];
                if (temp === null) {
                    console.error(`Key ${selector} not found in resources`);
                    v = temp;
                    break;
                }
                v = temp;
            }
            const value = String(v);
            return value;
        }
    },

    logging: {
        error: (e: Error): void => {
            console.log(e);
        }
    },

    hasSubscription: false,
    authenticated: false,
    user: undefined,
    timelineUrl: "",
    timelineSignOutUrl: "",

    init() {
        const auth = AuthProvider.getAuth();
        if (auth != null) {
            this.authenticated = true;
            this.user = auth.profile;
            this.hasSubscription = auth.hasSubscription;
            this.timelineUrl = auth.timelineUrl;
            this.timelineSignOutUrl = auth.timelineSignOutUrl;
        }
    },

    setAuth(authResult: IAuthResult): void {
        this.authenticated = authResult.type === AuthResultType.Success;
        this.user = authResult.profile;
        this.hasSubscription = authResult.hasSubscription;
        this.timelineUrl = authResult.timelineUrl;
        this.timelineSignOutUrl = authResult.timelineSignOutUrl;

        if (authResult.type === AuthResultType.Success)
            AuthProvider.setAuth(authResult);

        this.authChanged.next(this.user);
    },

    signOut(): void {
        AuthProvider.clear();
        this.authenticated = false;
        this.hasSubscription = false;
        this.user = null;
        this.authChanged.next(null);
    }
});
import { Formik, FormikErrors, FormikHelpers, FormikTouched } from 'formik';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import styles from './WesForm.module.scss';

export interface IFieldProps {
    label: string;
    name: string;
    initialValue?: string | number;
    required?: boolean;
    maxLength?: number;
    type?: 'text' | 'number' | 'email' | 'dropdown' | 'checkbox' // TODO: date range & year (4 max length, numeric)
    renderFormElement?: (
        values: any, 
        handleChange: React.ChangeEventHandler<any>, 
        errors: FormikErrors<any>
    ) => JSX.Element;
}

export interface IProps {
    validation: any;
    fields: IFieldProps[];
    onSubmit: (values: any) => void;
    bindSubmit: (submit: () => void) => void;
}

export default class WesForm extends React.Component<IProps> {
    render() {
        let initialValues: any = {};
        this.props.fields.forEach((item) => { 
            initialValues[item.name] = item.initialValue ?? '';
        });
        return (
            <Formik
                validationSchema={this.props.validation}
                onSubmit={this.doSubmit}
                initialValues={initialValues}
            >
                {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    isValid,
                    errors,
                    setFieldValue,
                    setFieldTouched,
                    submitForm
                }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        {this.props.bindSubmit(submitForm)}
                        {this.props.fields.map((field, index) => 
                            <Form.Group key={index} as={Row} className="mb-3" controlId={field.name}>
                                <Form.Label column sm="5">
                                    {this.renderLabel(field)}
                                </Form.Label>
                                {this.renderField(field, values, handleChange, errors)}
                            </Form.Group>
                        )}
                    </Form>
                )
            }
            </Formik>
        );
    }

    renderField(field: IFieldProps,
                values: any, 
                handleChange: React.ChangeEventHandler<any>, 
                errors: FormikErrors<any>) {

        if (field.renderFormElement != null) {
            return field.renderFormElement(values, handleChange, errors);
        }

        const type = field.type || 'text';
        return (
            <Col sm="7">
                <Form.Control
                    type={type}
                    name={field.name}
                    value={values[field.name]}
                    onChange={handleChange}
                    maxLength={field.maxLength}
                    isInvalid={!!errors[field.name]}
                />
                <Form.Control.Feedback type="invalid">
                    {errors[field.name]}
                </Form.Control.Feedback>
            </Col>
        );
    }


    renderLabel(field: IFieldProps) : JSX.Element {
        const req = field.required ?? true;
        return (
            <>
                <span className={styles.formLabel}>{field.label}</span>
                {req && (
                    <span className={styles.requiredIcon}>*</span>
                )}
            </>
        );
    }

    doSubmit = (values: any, form: FormikHelpers<any>) => {
        this.props.onSubmit(values);
        form.resetForm({});
    }
}
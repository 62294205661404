import React from 'react';
import { Modal } from 'react-bootstrap';
import { ReactComponent as Logo } from '../Assets/Images/logo.svg';

export interface IState {
    loading: boolean;
}

export interface IProps {
    orderId: number;
    show: boolean;
    onClose: () => void;
}

export default class Receipt extends React.Component<IProps, IState> {
    state: IState = { loading: false };
    componentDidMount() {

    }

    render() {
        return (
            <Modal show={this.props.show}>
                <Modal.Header closeButton={true} onHide={() => this.props.onClose()}>
                    <Modal.Title><Logo /></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div>TODO: {this.props.orderId}</div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}
import React from 'react';
import { WesContext } from '../../Contexts/WesContext';
import { IPendingVerificationCredential } from '../../Services/DataServiceModels';
import UploadDocument from '../../UploadDocument/UploadDocument';
import { ReactComponent as Upload } from '../../Assets/Images/upload.svg';
import { ReactComponent as RequiredDocs } from '../../Assets/Images/required-docs.svg';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Stack } from 'react-bootstrap';

export interface IProps {
    credential: IPendingVerificationCredential;
    onChange: () => void;
    onReqDocsClick: () => void;
}

interface IState {
    showUpload: boolean;
}

export default class PendingActions extends React.Component<IProps, IState> {
    static contextType = WesContext;
    res = (key: string, ...val: any[]) => this.context.resources.get(key, val);
    
    state: IState = {
        showUpload: false
    };

    render() {
        return (
            <>
                <UploadDocument 
                    show={this.state.showUpload} 
                    credential={this.props.credential}
                    onComplete={didUpload => { 
                        this.setState({ showUpload: false })
                        if (didUpload)
                            this.props.onChange();
                    }}
                />
                <Stack direction="horizontal" gap={2}>
                    <OverlayTrigger
                        placement={"top"}
                        overlay={
                            <Tooltip id="UploadDocuments">
                                {this.res("UploadDocuments")}
                            </Tooltip>
                        }>
                        <div onClick={() => this.setState({ showUpload: true })}>
                            <Upload />
                        </div>
                    </OverlayTrigger>
                    <OverlayTrigger
                        placement={"top"}
                        overlay={
                            <Tooltip id="ViewRequiredDocuments">
                                {this.res("ViewRequiredDocuments")}
                            </Tooltip>
                        }>
                        <div onClick={this.props.onReqDocsClick}>
                            <RequiredDocs />
                        </div>
                    </OverlayTrigger>
                </Stack>
            </>
        );
    }


    onView() : void {
        // TODO: no idea what to show here - there is no static content yet anyhow
    }
}
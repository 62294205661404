import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import styles from './WesModal.module.scss';
import { ReactComponent as CloseIcon } from '../Assets/Images/exit.svg';
import { ReactComponent as NextIcon } from '../Assets/Images/arrow-forward.svg';
import { ReactComponent as PrevIcon } from '../Assets/Images/arrow-back.svg';

export interface IProps {
    title: string;
    show: boolean;
    onClose?: (didComplete: boolean) => void;
    prevBtn?: IButtonProps;
    nextBtn?: IButtonProps;
}

export interface IButtonProps {
    visible: boolean;
    onClick: () => void;
    text: string;
    enabled: boolean;
}

export default class WesModal extends React.Component<IProps> {
    render() {
        return (
            <Modal 
                contentClassName={styles.modalContent}
                show={this.props.show}
                backdrop="static"
                keyboard={false}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered={true}>

                <Modal.Header>
                    <Modal.Title>
                        <div className={styles.title}>{this.props.title}</div>
                    </Modal.Title>
                    {this.props.onClose && (<CloseIcon onClick={() => {
                        this.props.onClose!(false);
                    }} />)}
                </Modal.Header>

                <Modal.Body bsPrefix={styles.modalBody}>                    
                    {this.props.children}
                </Modal.Body>

                {this.showBar() &&
                    <Modal.Footer className={styles.footer}>
                        <Container>
                            <Row>
                                <Col onClick={this.onPrev}>
                                    {this.renderPrev()}
                                </Col>
                                <Col onClick={this.onNext}>
                                    {this.renderNext()}
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Footer>
                }
            </Modal>
        );
    }

    renderNext() : JSX.Element {
        const btn = this.props.nextBtn;
        if (btn?.visible !== true)
            return <></>;

        let btnCls = styles.footerBtn;
        let iconCls = '';
        if (btn.enabled !== true) {
            btnCls += ` ${styles.disabled}`;
            iconCls += styles.disabled;
        }

        return (
            <div className={styles.nextBtn}>
                <a href="#" className={btnCls}>{btn.text}</a>
                <NextIcon className={iconCls} />
            </div>
        );
    }

    renderPrev() : JSX.Element {
        if (this.props.prevBtn?.visible === true) {
            return (
                <>
                    <PrevIcon />
                    <a href="#" className={styles.footerBtn}>{this.props.prevBtn!.text}</a>
                </>
            );
        }
        return (<div>&nbsp;</div>);
    }

    showBar(): boolean {
        return (
            this.props.nextBtn?.visible === true ||
            this.props.prevBtn?.visible === true
        );
    }

    onPrev = () => {
        this.runClick(this.props.prevBtn!);
    }

    onNext = () => {
        this.runClick(this.props.nextBtn!);
    }

    runClick(btn: IButtonProps): void {
        if (btn != null && btn.visible && btn.enabled === true)
            btn.onClick();
    }
}
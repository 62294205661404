export interface ICredential {
    id?: number;    
    country: string;
    organization: string;
    certificate: string;
    firstName: string;
    middleName: string;
    lastName: string;
    fieldOfStudy: string;
    yearAwarded?: number;
    yearStart?: number;
    yearFinish?: number;
    isHst: boolean;
    isFree: boolean;
}

export interface IPendingVerificationCredential extends ICredential {
    orderNumber: string;
    isMissingTranscript: boolean;
    isMissingDegreeCertificate: boolean;
}

export interface ICountry {
    id: number;
    name: string;
}

export interface IGatewayProcess {
    forwardUrl: string;
}

export interface IMyCredential extends ICredential {
    verificationDate: string;
    documents: IDocument[];
}

export interface IDocument {
    id: number;
    name: string;
    credentialId?: number;
}

export interface IShareRequest {
    documents: IDocument[];
    recipients: IRecipient[];
    isHst: boolean;
}

export interface IRecipient {
    name: string;
    email: string;
    organization: string;
}

export enum OrderType {
    CredentialVerification = 1,
    ShareCredential = 2,
    Subscription = 3
}

export interface IShareHistoryItem {
    id: number;
    recipient: string;
    email: string;
    documentName: string;
    date: Date;
    isViewed: boolean;
    credentialName: string;
}

export interface IOrderProcessComplete {
    amount: number;
    orderNumber: string;
    type: OrderType;
    json: string;
}

export interface IOrder {
    id: number;
    type: OrderType;
    amount: number;
    date: string;
}

export interface IAuthResult {
    profile: IProfile;
    type: AuthResultType;
    token: string;
    expires: number;
    hasSubscription: boolean;
    timelineUrl: string;
    timelineSignOutUrl: string;
}

export interface IProfile {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    isICAP: boolean;
    isCanApp: boolean;
    audienceType: AudienceType;
}

export interface INewSubscription {
    isHst: boolean;
    isDraft: boolean;
}

export interface IPdfResult {
    success: boolean
}

export enum AuthResultType {
    Success = 1,
    Invalid = 2,
    TimeOut = 3
}

export enum AudienceType {
    ICAP = 0,
    NONICAP = 1,
    DRAFT = 2
}
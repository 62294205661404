import React from "react";
import { Container } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import FormHeader from "../../Components/FormHeader";
import { WesContext } from "../../Contexts/WesContext";
import { ICredential } from "../../Services/DataServiceModels";
import styles from "./ReviewCredential.module.scss";

export interface IProps {
    credential?: ICredential;
}

export default class ReviewCredential extends React.Component<IProps> {   
    static contextType = WesContext;
    res = (key: string) => this.context.resources.get(key);

    render() {
        return (
            <>
                <FormHeader 
                    title={this.res("Review")}
                    subtitle={this.res("ReviewVerificationText")}
                />
                <div className={styles.container}>
                    <div className={styles.title}>
                        {this.res("YourEducation")}
                    </div>
                    <Container>
                        {/*{this.renderRow("FirstName", "firstName")}*/}
                        {/*{this.renderRow("MiddleName", "middleName")}*/}
                        {/*{this.renderRow("LastName", "lastName")}*/}
                        {this.renderRow("CountryOrTerritoryOfEducation", "country")}
                        {this.renderRow("NameOfTheInstitution", "organization")}
                        {this.renderRow("NameOfDiplomaCertificate", "certificate")}
                        {this.renderRow("FieldOfStudy", "fieldOfStudy")}
                        {this.renderRow("YearAwarded", "yearAwarded")}
                        {this.renderRowRaw(
                            "YearsAttended", 
                            `${this.props.credential?.yearStart} - ${this.props.credential?.yearFinish}`
                        )}
                    </Container>
                </div>
            </>
        );
    }

    renderRow(labelKey: string, accessor: keyof ICredential) {
        const c = this.props.credential;
        const value = c == null ? "" : c![accessor]?.toString() || "";
        
        return this.renderRowRaw(labelKey, value);
    }

    renderRowRaw(labelKey: string, value: string) {
        return (
            <Row className={styles.row}>
                <Col><span className={styles.label}>{this.res(labelKey)}:</span></Col>
                <Col><span className={styles.value}>{value}</span></Col>
            </Row>
        );
    }
}
import React from "react";
import { Stack } from "react-bootstrap";
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";
import { Link } from "react-router-dom";
import { WesContext, WesContextType } from "../Contexts/WesContext";
import styles from "./NavbarUserInfo.module.scss";
import { ReactComponent as ProfileIcon } from "../Assets/Images/profile-icon.svg";
import Config from '../Assets/Config';

export interface IState {
    show: boolean;
}

export default class NavbarUserInfo extends React.Component<any, IState> {
    static contextType = WesContext;
    res = (key: string, ...val: any[]) => this.context.resources.get(key, val);

    state: IState = { show: false };
    target = React.createRef<HTMLAnchorElement>();

    render() {
        const { user } = this.context;
        const name = `${user.firstName} ${user.lastName}`;
        return (
            <>
                <a href="#" ref={this.target} onClick={e => {
                    e.preventDefault();
                    this.setState({ show: !this.state.show });
                }}>
                    <Stack direction="horizontal" gap={3}>
                        <Stack direction="vertical" gap={0}>
                            <div className={styles.welcomeUserName}>{this.res("Welcome")}, {name}</div>
                            <div className={styles.grsNumber}>{this.res("WESGRS")}# {user.id}</div>
                        </Stack>
                        <ProfileIcon />
                    </Stack>
                </a>
                <Overlay 
                    show={this.state.show} 
                    target={this.target}
                    placement="bottom">

                    <Popover id="popover-basic">
                        <Popover.Body>
                            <Stack direction="vertical" gap={1}>
                                <span>{name}</span>
                                <span>{user.email}</span>
                                <span>{this.res("WESGRS")}# {user.id}</span>
                                <hr />
                                <a href={this.context.timelineUrl}>{this.res("AccountSettings")}</a><br />
                                <Link to="/transactionhistory" onClick={() => this.setState({ show: false })}>{this.res("TransactionHistory")}</Link><br />
                                <a href="#" onClick={e => {
                                    e.preventDefault();
                                    this.context.signOut();
                                    window.location.href = this.context.timelineSignOutUrl;
                                }}>{this.res("Logout")}</a>
                            </Stack>
                        </Popover.Body>
                    </Popover>
                </Overlay>
            </>
        );
    }
}
import React from 'react';
import { WesContext } from '../../Contexts/WesContext';
import styles from "./FileUploadList.module.scss";

export default class UploadInfo extends React.Component {
    static contextType = WesContext;
    res = (key: string, ...val: any[]) => this.context.resources.get(key, val);

    render() {
        return (
            <div>
                <h1>WES accepts digitally uploaded degree certificates only.</h1>
                <p>For the complete list of documents yop need to submit, view your required documents.</p>
                <p>Not all documents are eligible for digital upload. If your document requirements indicate that you can send photocopies of your degree certificates and translation, proceed by clicking the Next button below</p>
                <p><strong>File Requirements</strong></p>
                <ul>
                    <li>Upload only PDF or JPEG Files</li>
                    <li>The maximum file ssize is 10 MB</li>
                    <li>You can upload a total of 20 files. Please consider combining several pages into one file if they are related.</li>
                    <li>Do no password-your PDF.</li>
                    <li>Only upload correct, clear, legible documents. Your documents cannot be deleted once they are uploaded.</li>
                    <li>Do no upload academic transcripts or marksheet, upload is available only for degree certificates and translations.</li>
                </ul>
             </div>
       );
    }
}
import React from 'react';
import { WesContext } from '../Contexts/WesContext';
import StepProgressBar from './StepProgressBar';
import WesModal, { IButtonProps } from './WesModal';
import styles from './WesWizardModal.module.scss';

export interface IProps {
    title: string;
    step: number;
    show: boolean;
    steps: string[];

    nextText: string;
    backText?: string;
    nextEnabled: boolean;

    onClose: () => void;
    onBack: () => void;
    onNext: () => void;
    onValidChanged?: (valid: boolean) => void;
}

export default class WesWizardModal extends React.Component<IProps> {
    static contextType = WesContext;
    
    render() {
        const res = (key: string) => this.context.resources.get(key);

        const prevBtn: IButtonProps = {
            text: (this.props.backText || res("Back")),
            visible: this.props.step > 1,
            onClick: this.props.onBack,
            enabled: true
        };
        const nextBtn: IButtonProps = {
            text: this.props.nextText,
            visible: true,
            onClick: this.props.onNext,
            enabled: this.props.nextEnabled
        };

        return (
            <WesModal 
                show={this.props.show}                
                title={this.props.title}
                nextBtn={nextBtn}
                prevBtn={prevBtn}
                onClose={() => {
                    if (this.props.onClose != null)
                        this.props.onClose();
                }}>
                <StepProgressBar 
                    step={this.props.step} 
                    steps={this.props.steps}
                />
                <div className={styles.contentView}>
                    <div className={styles.content}>
                        {this.props.children}
                    </div>
                </div>
            </WesModal>
        )
    }
}
import React from "react";
import DataService from "../Services/DataService";
import { IShareHistoryItem } from "../Services/DataServiceModels";
import { WesContext } from "../Contexts/WesContext";
import FormatService from "../Services/FormatService";
import DashboardTitle from "../Components/DashboardTitle";
import WesDataList, { IColumn } from "../Components/WesDataList";

interface IState {
    data: IShareHistoryItem[],
    loading: boolean
}

export default class ShareHistoryList extends React.Component<{}, IState> {
    static contextType = WesContext;
    res = (key: string, ...val: any[]) => this.context.resources.get(key, val);

    state: IState = {
        loading: false,
        data: []
    }

    componentDidMount() {
        this.loadData();
    }

    render() {
        const cols: IColumn[] = [{
            title: this.res("ShareDate"),
            render: (item: IShareHistoryItem) => FormatService.formatDate(item.date)
        }, {
            title: this.res("Recipient"),
            render: (item: IShareHistoryItem) => item.recipient
        }, {
            title: this.res("CredentialName"),
            render: (item: IShareHistoryItem) => item.credentialName
        }, {
            title: this.res("Document"),
            render: (item: IShareHistoryItem) => item.documentName
        }, {
            title: this.res("Status"),
            render: (item: IShareHistoryItem) => {
                const state = item.isViewed === true ? "Viewed" : "Delivered";
                return this.res("ShareState" + state);
            }
        }];
        return (
            <>
                <DashboardTitle
                    title={this.res("ShareHistory")}
                    badge={this.state.data.length}
                />
                <WesDataList
                    loading={this.state.loading}
                    data={this.state.data}
                    columns={cols}
                />
            </>
        );
    }


    async loadData() {
        try {
            this.setState({ loading: true });
            const result = await DataService.getShareHistory();
            this.setState({ loading: false, data: result });
        }
        catch (ex) {
            this.context.logging.error(ex);
            this.setState({ loading: false });
        }
    }
}
import React from "react";
import Config from "../../Assets/Config";
import { WesContext } from "../../Contexts/WesContext";
import styles from './SubTotal.module.scss';

export interface IProps {
    documents: number;
    recipients: number;
}

export default class SubTotal extends React.Component<IProps> {
    static contextType = WesContext;
    res = (key: string, ...val: any[]) => this.context.resources.get(key, val);
        
    render() {
        const rl = this.props.recipients;
        const dl = this.props.documents;
        const price = Config.calcSharePrice(rl, dl);  
        const subtotal = `(${dl} ${this.res("Documents")}, ${rl} ${this.res("Recipients")}): ${Config.currency} \$${price}`;

        return (<div className={styles.label}>{this.res("Subtotal")} {subtotal}</div>);
    }
}
import React from 'react';
import FormHeader from '../../Components/FormHeader';
import { WesContext } from "../../Contexts/WesContext";

export default class RequiredDocsInfo extends React.Component {
    static contextType = WesContext;
    res = (key: string) => this.context.resources.get(key);
    resNested = (key: string) => this.context.resources.getNested(key);

    render() {
        return (
            <>
                <FormHeader
                    title={this.res("RequiredDocuments")}
                />
                <div style={{ padding: "10px 20px" }}>
                    <div>
                        {this.resNested("RequiredDocumentsContent:Intro")}
                        <br />
                        <ul>
                            <li>{this.resNested("RequiredDocumentsContent:list1")}</li>
                            <li>{this.resNested("RequiredDocumentsContent:list2")}</li>
                        </ul>
                        {this.resNested("RequiredDocumentsContent:conclusion")}
                    </div>
                </div>
            </>
        );
    }
}
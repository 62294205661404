import * as Yup from "yup";
import { WesContextType } from "../Contexts/WesContext";

export default class Validations {

    public static personName(context: WesContextType, required?: boolean): any {
        const req = required ?? true;
        return req ? Validations.reqString(context, 50) : Validations.maxLength(context, 50);
    }

    public static maxLength(context: WesContextType, maxLength: number): any {
        const msg = context.resources.get("FieldMaxLength", maxLength);

        return Yup
            .string()
            .max(maxLength, msg);
    }

    public static reqString(context: WesContextType, maxLength: number): any {
        const msg = context.resources.get("FieldRequiredAndMaxLength", maxLength);

        return Yup
            .string()
            .test('len', msg, x => {
                const len = x?.length || 0;
                return len <= maxLength;
            })
            .required(msg);
    }



    public static email(context: WesContextType): any {
        return Validations
            .reqString(context, 255)
            .email(context.resources.get("EmailIsInvalid"));
    }


    public static year(context: WesContextType, required: boolean): any {
        let v = Yup.number().max(
            new Date().getFullYear(), 
            context.resources.get("FieldYearMustBeLtEqCurrentYear")
        );

        if (required) {
            v = v.required(context.resources.get("FieldRequired"));
        }
        return v;
    }
}
import React from "react";
import Badge from "react-bootstrap/Badge";

export interface IProps {
    count: number;
    alert?: boolean;
}

export default class WesBadge extends React.Component<IProps> {
    render() {
        if (this.props.alert === true) {
            return (<Badge bg="danger" text="light">{this.props.count}</Badge>)
        }

        return (<Badge bg="light" text="dark">{this.props.count}</Badge>);
    }
}
import React from "react";
import { Form, FormCheck } from "react-bootstrap";
import Config from "../../Assets/Config";
import FormHeader from "../../Components/FormHeader";
import { WesContext } from "../../Contexts/WesContext";
import styles from "./OrderSummary.module.scss";

export interface IProps {
    handleIsCanCheckbox: (e: any) => void;
    isHst: boolean;
    price: number;
}

export default class OrderSummary extends React.Component<IProps> {
    static contextType = WesContext;
    res = (key: string) => this.context.resources.get(key);

    render() {
        const price = Config.formatCurrency(this.props.price);
        return (
            <>
                <FormHeader
                    title={this.res("OrderSummary")}
                />
                <div className={styles.priceLabel}>{price}</div>

                <div hidden={!this.context.user.isCanApp}>
                    <Form>
                        <Form.Check
                            type='checkbox'
                            label={this.res("IamInCanada")}
                            checked={this.props.isHst}
                            onChange={this.props.handleIsCanCheckbox}

                        />
                    </Form>
                </div>

            </>
        );
    }
}
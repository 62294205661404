import AuthProvider from "./AuthProvider";
import {
    ICountry,
    IMyCredential,
    IPendingVerificationCredential,
    IShareHistoryItem,
    IShareRequest,
    IOrder,
    IAuthResult,
    IGatewayProcess,
    ICredential,
    IOrderProcessComplete,
    INewSubscription
} from "./DataServiceModels";

export default class DataService {

    private static getData<T>(url: string): Promise<T> {
        return fetch(url, { headers: DataService.getHeaders() })
            .then(x => x.json())
            .then(x => x as T);
    }

    private static post<T>(url: string, data: any): Promise<T> {
        return fetch(url, DataService.getPostRequest(data))
            .then(x => x.json())
            .then(x => x as T);
    }

    private static getHeaders() {
        const headers = new Headers();
        const auth = AuthProvider.getAuth();
        if (auth != null)
            headers.append('Authorization', `Bearer ${auth.token}`);

        headers.append('Content-Type', 'application/json');
        return headers;
    }

    private static getPostRequest(data?: any): RequestInit {
        var request: RequestInit = { method: 'POST' };
        request.headers = DataService.getHeaders();
        if (data) {
            request.body = JSON.stringify(data);
        }
        return request;
    }

    public static processOrder(values: string): Promise<IOrderProcessComplete> {
        return DataService.post<IOrderProcessComplete>("/orders/process", { value: values });
    }

    public static getCountries(): Promise<ICountry[]> {
        return DataService.getData<ICountry[]>("/reference/countries");
    }

    public static getPdf(accessKey: string): Promise<any> {
        return fetch("/reference/viewshare/" + accessKey);
    }

    public static createIcapSubscription(): Promise<Response> {
        return fetch("/subscription/createicapsubscription", { headers: DataService.getHeaders() });
    }

    public static declineGrs(): Promise<Response> {
        return fetch("/profile/declinegrs", { headers: DataService.getHeaders() });
    }

    public static authenticate(token: string): Promise<IAuthResult> {
        return DataService.post<IAuthResult>("/authentication/external", { token });
    }

    public static getShareHistory(): Promise<IShareHistoryItem[]> {
        return DataService.getData<IShareHistoryItem[]>("/share/");
    }

    public static getMyCredentials(): Promise<IMyCredential[]> {
        return DataService.getData<IMyCredential[]>("/credentials/");
    }

    public static getMyPendingCredentials(): Promise<IPendingVerificationCredential[]> {
        return DataService.getData<IPendingVerificationCredential[]>("/credentials/pending");
    }

    public static getTransactionHistory(): Promise<IOrder[]> {
        return DataService.getData<IOrder[]>("/orders/");
    }

    public static processShare(request: IShareRequest): Promise<IGatewayProcess> {
        return DataService.post<IGatewayProcess>("/share", request);
    }

    public static processNewCredential(credential: ICredential): Promise<IGatewayProcess> {
        return DataService.post<IGatewayProcess>("/credentials/new", credential);
    }

    public static processSubscription(subOrder: INewSubscription): Promise<IGatewayProcess> {
        return DataService.post<IGatewayProcess>("/subscription/pay", subOrder);
    }

    public static verifyAuth(authToken: string): Promise<IAuthResult> {
        return DataService.post<IAuthResult>("/auth", { authToken });
    }

    public static refreshToken(): Promise<IAuthResult> {
        return DataService.getData<IAuthResult>("/authentication/refresh");
    }

    public static checkCredFree(): Promise<boolean> {
        return DataService.getData<boolean>("/credentials/isfree")
    }

    public static async uploadDocuments(credentialId: number, files: File[]): Promise<void> {
        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append("files", files[i]);
        }
        const auth = AuthProvider.getAuth();

        // TODO: could use axios to track progress
        await fetch(
            `/credentials/${credentialId}/upload`,
            {
                method: 'POST',
                body: formData,
                headers: {
                    'Authorization': `Bearer ${auth?.token}`
                }
            }
        );
    }
}
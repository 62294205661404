import './App.scss';
import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    NavLink
} from "react-router-dom";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import TransactionHistoryList from './Orders/TransactionHistoryList';
import MyDocumentList from './Credentials/MyDocumentList';
import PendingVerificationList from './Credentials/PendingVerificationList';
import ShareHistoryList from './Share/ShareHistoryList';
import NavbarUserInfo from './Components/NavbarUserInfo';
import Auth from './Auth/Auth';
import NotAuthenticated from './Auth/NotAuthenticated';
import ExpiredPdf from './ExpiredPdf/ExpiredPdf';
import PaymentCallback from './Orders/PaymentCallback';
import AddSubscription from './Subscriptions/AddSubscription';
import ExistingSubscriber from './Subscriptions/ExistingSubscriber';
import { WesContext } from './Contexts/WesContext';
import { ReactComponent as Logo } from './Assets/Images/logo.svg';
import { Subscription } from 'rxjs';
import AuthSignOut from "./AutoSignOut";
import ShareDocViewer from './Share/ShareDocViewer';


export default class App extends React.Component {
    static contextType = WesContext;
    res = (key: string, ...val: any[]) => this.context.resources.get(key, val);
    sub: Subscription = this.context.authChanged.subscribe(
        () => this.forceUpdate()
    );

    componentWillUnmount() {
        this.sub.unsubscribe();
    }

    render() {
        this.context.init();
        return (
            <Router>
                <div className="header">
                    <Navbar className="header">
                        <Container>
                            <Navbar.Brand>
                                <Logo />
                            </Navbar.Brand>
                            <Navbar.Collapse role="menu" className="justify-content-end">
                                <Navbar.Text>
                                    {this.showContent() && <NavbarUserInfo />}
                                </Navbar.Text>
                            </Navbar.Collapse>
                        </Container>
                    </Navbar>
                </div>
                {this.renderMenu()}
                <div className="content">
                    {this.renderContent()}
                </div>
                <AuthSignOut />
            </Router>
        );
    }

    renderMenu(): React.ReactNode {
        if (this.showContent() !== true)
            return (<></>);
        return (
            <div className="menu">
                <Nav defaultActiveKey="/" className="flex-column">
                    <NavLink
                        to="/"
                        exact={true}
                        className="menuLink"
                        activeClassName="activeMenuLink">{this.res("MyDocuments")}</NavLink>

                    <NavLink
                        to="/sharehistory"
                        exact={true}
                        className="menuLink"
                        activeClassName="activeMenuLink">{this.res("ShareHistory")}</NavLink>

                    <NavLink
                        to={{ pathname: "https://www.wes.org/wp-content/uploads/2021/11/Global-Records-Service-FAQ.pdf" }}
                        exact={true}
                        target="_blank"
                        className="menuLink"
                        activeClassName="activeMenuLink">
                        FAQs
                    </NavLink>

                </Nav>

            </div >
        );
    }
    pagePath = window.location.pathname;

    showContent(): boolean {
        return (
            this.pagePath !== "/selectapp" &&
            this.pagePath !== "/subscribe" &&
            this.pagePath !== "/auth" &&
            this.pagePath !== "/expiredpdf" &&
            this.pagePath !== "/share/view"
        );
    }

    renderContent(): React.ReactNode {
        const { authenticated } = this.context;
        return (
            <Switch>
                <Route
                    path="/auth"
                    render={(props) => <Auth {...props} />}
                />
                <Route
                    path="/share/view"
                    render={(props) => <ShareDocViewer {...props} />}
                />

                <Route path="/expiredpdf">
                    <div>
                        <ExpiredPdf />
                    </div>
                </Route>
                {authenticated === true && (
                    <Route
                        path="/payment"
                        render={(props) => <PaymentCallback {...props} />}
                    />
                )}
                {this.showContent() && (
                    <Route path="/transactionhistory">
                        <div style={{ margin: "20px" }}>
                            <TransactionHistoryList />
                        </div>
                    </Route>
                )}
                {this.showContent() && (
                    <Route path="/sharehistory">
                        <div style={{ margin: "20px" }}>
                            <ShareHistoryList />
                        </div>
                    </Route>
                )}
                {authenticated === true && (
                    <Route
                        path="/selectapp"
                        render={(props) => <ExistingSubscriber {...props} />}
                    />
                )}
                {authenticated === true && (
                    <Route
                        path="/subscribe"
                        render={(props) => <AddSubscription {...props} />}
                    />
                )}

                <Route path="/" render={props => this.getRoot()} />
            </Switch>
        );
    }


    getRoot(): React.ReactNode {
        const { authenticated } = this.context;

        if (authenticated === false)
            return (<NotAuthenticated />);
        return (
            <>
                <div style={{ margin: "20px" }}>
                    <MyDocumentList />
                </div>
                <div style={{ margin: "20px" }}>
                    <PendingVerificationList />
                </div>

            </>
        );
    }
}
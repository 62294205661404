import React from "react";
import { IRecipient } from "../../Services/DataServiceModels";
import { WesContext } from "../../Contexts/WesContext";
import WesDataList, { IColumn } from "../../Components/WesDataList";
import { Stack } from "react-bootstrap";
import styles from './RecipientList.module.scss';

export interface IProps {
    recipients: IRecipient[];
    onDelete?: (recipient: IRecipient) => void;
    onEdit?: (recipient: IRecipient) => void;
}

export default class RecipientList extends React.Component<IProps> {
    static contextType = WesContext;
    res = (key: string) => this.context.resources.get(key);

    render() {
        const { onDelete, onEdit } = this.props;

        let cols: IColumn[] = [{
            title: this.res("RecipientName"),
            render: (recipient: IRecipient) => recipient.name
        },{
            title: this.res("OrganizationInstitutionName"),
            render: (recipient: IRecipient) => recipient.organization
        }, {
            title: this.res("Email"),
            render: (recipient: IRecipient) => recipient.email
        }];
        
        if (onEdit && onDelete) {
            cols.push({
                title: this.res("Actions"),
                render: (recipient: IRecipient) => (
                    <Stack direction="horizontal" gap={3}>
                        <a 
                            href="#" 
                            className={styles.actionLink} 
                            onClick={e => {
                                e.preventDefault();
                                onEdit(recipient);
                            }}>{this.res("Edit")}</a>
                        
                        <a href="#" 
                            className={styles.actionLink} 
                            onClick={e => {
                                e.preventDefault();
                                onDelete(recipient);
                            }}>{this.res("Remove")}</a>
                    </Stack>
                )
            })
        }


        return (
            <WesDataList 
                loading={false}
                columns={cols}
                data={this.props.recipients}
            />
        );
    }
}
import React from "react";
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { ICredential, ICountry } from "../../Services/DataServiceModels";
import * as Yup from "yup";
import { FormikErrors } from "formik";
import { WesContext } from "../../Contexts/WesContext";
import Validations from "../../Services/Validations";
import WesForm, { IFieldProps } from "../../Components/WesForm";
import FormHeader from "../../Components/FormHeader";
import LoadingOverlay from "react-loading-overlay";
import DataService from "../../Services/DataService";
import { FloatingLabel } from "react-bootstrap";

export interface IProps {
    credential: ICredential | null;
    onCredentialChange: (creds: ICredential) => void;
    bindSubmit: (submit: () => void) => void;
}

interface IState {
    loading: boolean;
    countries: ICountry[];
    credential: ICredential | null;
    yearAwarded: number;
    yearStarted: number;
    yearFinished: number;
}

export default class CredentialForm extends React.Component<IProps> {
    static contextType = WesContext;
    res = (key: string) => this.context.resources.get(key);
    submitForm: any;

    state: IState = {
        loading: false,
        countries: [],
        credential: this.props.credential,
        yearStarted: this.props.credential?.yearStart || 0,
        yearFinished: this.props.credential?.yearFinish || 0,
        yearAwarded: this.props.credential?.yearAwarded || 0
    };

    validation = Yup.object().shape({
        country: Validations.reqString(this.context, 100),
        organization: Validations.reqString(this.context, 100),
        certificate: Validations.reqString(this.context, 100),
        yearAwarded: Validations.year(this.context, true),
        yearStart: Validations
            .year(this.context, true)
            .when(
                'yearFinish',
                (yearFinish: number, schema: any) => yearFinish && schema.max(yearFinish, this.res("YearStartedLessThanYearFinish"))
            ),
        yearFinish: Validations
            .year(this.context, true)
            .when(
                'yearAwarded',
                (yearAwarded: number, schema: any) => yearAwarded && schema.max(yearAwarded, this.res("YearFinishedLessThanYearAwarded"))
            )
    });

    handleYearAwardedChange(e: React.ChangeEvent<any>, h: React.ChangeEventHandler<any>) {
        h(e);
        var year = Number(e.target.value);
        // reset the start and end date also
        this.setState({ yearAwarded: year, yearFinished: 0, yearStarted: 0 });
    }

    handleYearStartedChange(e: React.ChangeEvent<any>, h: React.ChangeEventHandler<any>) {
        h(e);
        var year = Number(e.target.value);
        // reset the end date as well
        this.setState({ yearStarted: year, yearFinished: 0 });
    }

    handleYearFinishedChange(e: React.ChangeEvent<any>, h: React.ChangeEventHandler<any>) {
        h(e);
        var year = Number(e.target.value);
        this.setState({ yearFinished: year });
    }

    createFromYears() {
        let yearAwarded: number = this.state.yearAwarded;
        let options: number[] = [];
        if (yearAwarded !== 0) {
            for (let i = 0; i < 100; i++) {
                options.push(yearAwarded - i);
            }
        }
        return options;
    }

    createToYears() {
        let yearAwarded: number = this.state.yearAwarded;
        let yearStarted: number = this.state.yearStarted;
        let options: number[] = [];
        if (yearAwarded !== 0 && yearStarted !== 0) {
            for (let i = yearStarted; i <= yearAwarded; i++) {
                options.push(i);
            }

            // reverse the list for descending list
            options.reverse();
        }
        return options;
    }

    createAwardYears() {
        let options: number[] = [];
        let currYear = new Date().getFullYear();
        for (let i = 1900; i <= currYear; i++) {
            options.push(i);
        }
        // reverse the list for descending list
        options.reverse();
        return options;
    }

    async componentDidMount() {
        try {
            this.setState({ loading: true });
            const data = await DataService.getCountries();
            this.setState({ countries: data });
        }
        catch (ex) {
            this.context.logging.error(ex);
        }
        finally {
            this.setState({ loading: false });
        }
    }

    render() {
        const c = this.props.credential;

        const fields: IFieldProps[] = [{
            name: "country",
            label: this.res("CountryOrTerritoryOfEducation"),
            initialValue: c?.country,
            renderFormElement: (
                values: any,
                handleChange: React.ChangeEventHandler<any>,
                errors: FormikErrors<any>
            ) => (
                <Col>
                    <Form.Select
                        name="country"
                        onChange={handleChange}
                        value={values.country}
                        isInvalid={!!errors.country}
                    >
                        <option></option>
                        {this.state.countries.map(x => (<option key={`country-${x.id}`}>{x.name}</option>))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                        {errors.country}
                    </Form.Control.Feedback>
                </Col>
            )
        }, {
            name: "organization",
            initialValue: c?.organization,
            label: this.res("NameOfTheInstitution")
        }, {
            name: "certificate",
            initialValue: c?.certificate,
            label: this.res("NameOfDiplomaCertificate"),
            maxLength: 100
        },
        {
            name: "fieldOfStudy",
            initialValue: c?.fieldOfStudy,
            label: this.res("FieldOfStudy"),
            required: false,
            maxLength: 100
        },
        //{
        //    name: "yearAwarded",
        //    initialValue: c?.yearAwarded,
        //    type: "number",
        //    label: this.res("YearAwarded"),
        //    maxLength: 4,
        //},
        {
            name: "yearAwarded",
            label: this.res("YearAwarded"),
            initialValue: c?.yearAwarded,
            renderFormElement: (
                values: any,
                handleChange: React.ChangeEventHandler<any>,
                errors: FormikErrors<any>
            ) => (
                <Col>
                    <Form.Select
                        name="yearAwarded"
                        onChange={e => this.handleYearAwardedChange(e, handleChange)}
                        value={this.state.yearAwarded}
                    >
                        <option></option>
                        {this.createAwardYears().map(x => (<option key={x}>{x}</option>))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                        {errors.country}
                    </Form.Control.Feedback>
                </Col>
            )
        },
        {
            name: "yearsAttended",
            label: this.res("YearsAttended"),
            renderFormElement: (
                values: any,
                handleChange: React.ChangeEventHandler<any>,
                errors: FormikErrors<any>,

            ) => (
                <>
                    <Col>
                        <Form.Select
                            name="yearStart"
                            onChange={e => this.handleYearStartedChange(e, handleChange)}
                            value={this.state.yearStarted}
                            defaultValue={c?.yearStart}
                            isInvalid={!!errors.yearStart}
                        >
                            <option></option>
                            {this.createFromYears().map(x => (<option key={x}>{x}</option>))}
                        </Form.Select>

                        <Form.Control.Feedback type="invalid">
                            {errors.yearStart}
                        </Form.Control.Feedback>
                    </Col>
                    <Col>
                        <Form.Select
                            name="yearFinish"
                            onChange={e => this.handleYearFinishedChange(e, handleChange)}
                            value={this.state.yearFinished}
                            defaultValue={c?.yearFinish}
                            isInvalid={!!errors.yearFinish}
                        >
                            <option></option>
                            {this.createToYears().map(x => (<option key={x}>{x}</option>))}
                        </Form.Select>

                        <Form.Control.Feedback type="invalid">
                            {errors.yearFinish}
                        </Form.Control.Feedback>
                    </Col>
                </>
            )
        }];

        return (
            <LoadingOverlay active={this.state.loading}>
                <FormHeader
                    title={this.res("AddACredential")}
                />
                <WesForm
                    fields={fields}
                    validation={this.validation}
                    onSubmit={this.onSubmit}
                    bindSubmit={this.props.bindSubmit}
                />
            </LoadingOverlay>
        );
    }

    onSubmit = (values: ICredential): void => {
        this.props.onCredentialChange(values);
    }
}
import React from 'react';
import DataService from '../Services/DataService';
import FormatService from '../Services/FormatService';
import ShareModal from '../Share/ShareDocuments';
import FormCheck from 'react-bootstrap/FormCheck';
import DashboardTitle from '../Components/DashboardTitle';
import WesDataList, { IColumn } from '../Components/WesDataList';
import { WesContext } from '../Contexts/WesContext';
import { IDocument, IMyCredential } from '../Services/DataServiceModels';
import { Container, Row, Col } from 'react-bootstrap';
import styles from './MyDocumentList.module.scss';
import Config from '../Assets/Config';

interface IState {
    data: IMyCredential[];
    loading: boolean;
    selected: IDocument[];
}

export default class MyDocumentList extends React.Component<any, IState> {
    static contextType = WesContext;
    res = (key: string, ...val: any[]) => this.context.resources.get(key, val);

    state: IState = {
        loading: false,
        selected: [],
        data: []
    };

    componentDidMount() {
        this.loadData();
    }

    render() {
        const cols: IColumn[] = [{
            title: this.res("DocumentTypeName"),
            render: (item: IMyCredential) => item.certificate
        }, {
            title: this.res("AwardedBy"),
            render: (item: IMyCredential) => item.organization
        }, {
            title: this.res("Country"),
            render: (item: IMyCredential) => item.country
        }, {
            title: this.res("VerificationDate"),
            render: (item: IMyCredential) => FormatService.formatDate(item.verificationDate)
        }, {
            title: this.res("Share"),
            render: (item: IMyCredential) => (
                <Col className={styles.ckShareCol}>
                    <FormCheck
                    checked={this.isAllSelected(item)}
                    onChange={e => this.toggleAllSelected(e.currentTarget.checked, item)}
                /></Col>
            )
        }];

        return (
            <>
                <DashboardTitle 
                    title={this.res("YourGlobalRecords")}
                    badge={this.state.data.length}
                    subtitle={this.res("GlobalRecordsSubtitle")}
                    text={this.res("GlobalRecordsText", Config.sharePricePerAdd)}
                >
                    <ShareModal
                        documents={this.state.selected}
                        onClose={() => this.loadData()}
                    />
                </DashboardTitle>
                <WesDataList 
                    renderExpandRow={item => this.renderExpandRow(item)}
                    columns={cols}
                    loading={this.state.loading}
                    data={this.state.data}
                    noDataText={this.res("NoGlobalRecords")}
                />
            </>
        );
    }

    renderExpandRow = (item: IMyCredential) => {
        return (
            <Container>
                {item.documents.map((doc, index) => {
                    return (
                        <Row key={index} className={`${styles.expanderRow} ${styles.credentialContainer}`}>
                            <Col className={styles.credentialCol}>{doc.name}</Col>
                            <Col md={{ offset: 8}}>
                                <FormCheck
                                    checked={this.isSelected(doc)}
                                    onChange={e => this.toggleSelected(doc)}
                                />
                            </Col>
                        </Row>
                    );
                })}
            </Container>
        );
    }


    async loadData() {
        try {
            this.setState({ loading: true });
            var result = await DataService.getMyCredentials();
            this.setState({ data: result });
        }
        catch (ex) {
            this.context.logging.error(ex);
            
        }
        finally {
            this.setState({ loading: false });
        }
    }

    toggleAllSelected(selected: boolean, cred: IMyCredential) {
        cred.documents.forEach((doc) => {
            const index = this.state.selected.findIndex(x => x.id === doc.id);
            const exists = index !== -1;
            if (selected && !exists) {
                this.push(doc);
            }
            else if (!selected && exists) {
                this.state.selected.splice(index, 1);
            }
        });
        this.setState({ selected: this.state.selected });
    }

    toggleSelected(doc: IDocument): void {
        const index = this.state.selected.findIndex(x => x.id === doc.id);
        if (index !== -1) {
            this.state.selected.splice(index, 1);
        }
        else {
            this.push(doc);
        }
        this.setState({ selected: this.state.selected });
    }

    push(doc: IDocument): void {
        const cred = this.state.data.find(x => x.id === doc.credentialId!)!;

        this.state.selected.push({
            id: doc.id,
            name: `${cred.certificate} ${doc.name}`
        });
    }

    isAllSelected(cred: IMyCredential) : boolean {
        return cred.documents.every(x => this.isSelected(x));
    }

    isSelected(doc: IDocument) : boolean {
        return this.state.selected.findIndex(x => x.id === doc.id) > -1;
    }
}
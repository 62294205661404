import React from 'react';
import LoadingOverlay from 'react-loading-overlay';
import FormHeader from '../Components/FormHeader';
import StepProgressBar from '../Components/StepProgressBar';
import WesModal, { IButtonProps } from '../Components/WesModal';
import { WesContext } from "../Contexts/WesContext";
import DataService from '../Services/DataService';
import { 
    IDocument,
    IOrderProcessComplete, 
    IShareRequest, 
    OrderType 
} from '../Services/DataServiceModels';
import DocumentList from '../Share/Components/DocumentList';
import RecipientList from '../Share/Components/RecipientList';
import styles from './PaymentCallBack.module.scss';
import loading from '../Assets/Images/803.gif';

export interface IProps {
    location: any;
    history: any;
}

enum State {
    Loading,
    Error,
    Success,
    Declined
}

interface IState {
    status: State;
    orderInfo: IOrderProcessComplete | null;
}

export default class PaymentCallback extends React.Component<IProps, IState> {
    static contextType = WesContext;
    res = (key: string, ...val: any[]) => this.context.resources.get(key, val);
    state: IState = { 
        status: State.Loading,
        orderInfo: null
    };
    
    async componentDidMount() {
        const values = this.props.location.search.substring(1); // trim ?
        const declined = values.indexOf('auth') === -1;
        if (declined) {
            this.setState({ status: State.Declined });
        }
        else {
            try {
                const result = await DataService.processOrder(values);
                this.context.hasSubscription = true;
                this.setState({ 
                    orderInfo: result,
                    status: State.Success
                });
            }
            catch (e) {
                this.context.logging.error(e);
                this.setState({ status: State.Error });
            }
        }
    }

    render() {
        const nextBtn: IButtonProps = {
            text: this.res("ViewWesDashboard"),
            visible: this.state.status !== State.Loading,
            enabled: true,
            onClick: () => this.props.history.replace("/")
        };
        const comp = this.getComponent();
        return (
            <WesModal 
                show={true}
                title={comp.title}
                nextBtn={nextBtn}>
                {comp.body}
            </WesModal>
        );
    }

    getComponent() {
        switch (this.state.status) {
            case State.Loading: 
                return {
                    title: 'PROCESSING',
                    body: (<img src={loading} alt="loading" className={styles.loadingImg}/>)
                };

            case State.Declined:
                return {
                    title: 'PAYMENT DECLINED',
                    body: (<>Your payment was declined</>)
                };

            case State.Error:
                return {
                    title: 'ERROR',
                    body: (<div className={styles.contentDiv}>There was an error processing your order</div>)
                };

            case State.Success:
                return {
                    title: this.getTitle(),
                    body: this.getOrderBody()
                };
        }
    }


    getFormHeader(): React.ReactNode {

        return (
            <div className={styles.contentDiv}>
                <FormHeader
                    title={this.res("ThankYouForSubmittingPayment")}
                    subtitle={this.res("ConfirmationEmailSentTo", this.context.user.email)}
                />
                <span className={styles.refNumberPad}>{this.res("YourWesGrsRefNumber", this.context.user.id)}</span>
            </div>
        );
    }

    getTitle(): string {
        const oi = this.state.orderInfo!;
        switch (oi.type) {
            case OrderType.Subscription: return this.res("YoureIn");
            case OrderType.CredentialVerification: return this.res("VerifyNewCredential");
            case OrderType.ShareCredential: return this.res("ShareDocuments");
        }
    }

    getOrderBody() : React.ReactNode {
        const oi = this.state.orderInfo!;
        switch (oi.type) {
            case OrderType.Subscription: return this.getFormHeader();
            case OrderType.ShareCredential: return this.getShareBody(oi);
            case OrderType.CredentialVerification: return this.getCredBody(oi);
        }
    }

    getShareBody(oi: IOrderProcessComplete) : React.ReactNode {
        const req = JSON.parse(oi.json); // warning: this serializes on the server as C# casing, not JS casing

        // create the documents list (casing does not match IDucments class from response)
        var docs = req.Documents.map((doc: { Id: any; Name: any; CredentialId: any; }) => {
            var m: IDocument = {id: doc.Id, name: doc.Name, credentialId: doc.CredentialId};
            return m;
        });

        return (
            <div className={styles.contentDiv}>
                <StepProgressBar 
                    steps={[
                        this.res("AddRecipients"),
                        this.res("Review"),
                        this.res("Payment")
                    ]}
                    step={3}
                />               
                {this.res("ViewInSharedHistory")}

                <DocumentList documents={docs} />
                
                <strong>{this.res("Recipients")}</strong>
                <ul>
                    {req.Recipients.map((r: any, index: number) => 
                        <li key={index}>{r.Name} - {r.Organization} - {r.Email}</li>
                    )}
                    </ul>               
            </div>
        );
    }

    getCredBody(oi: IOrderProcessComplete) : React.ReactNode {
        return (
            <div>
                <StepProgressBar 
                    steps={[
                        this.res("AddCredential"),
                        this.res("RequiredDocuments"),
                        this.res("Review"),
                        this.res("Payment")
                    ]}
                    step={4}
                />
                {this.getFormHeader()}
                <div className={styles.NextSteps}>
                <p><strong>Next Steps</strong></p>
                <ul>
                    <li>Upload Documents (Degree Certificate)</li>
                    <li>Contact Your University to Send Documents to WES</li>
                    </ul>
                    </div>
            </div>
        );
    }
}
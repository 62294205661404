import React from "react";
import { IRecipient } from "../../Services/DataServiceModels";
import Validations from "../../Services/Validations";
import * as Yup from "yup";
import { WesContext } from "../../Contexts/WesContext";
import WesForm, { IFieldProps } from "../../Components/WesForm";
import WesButton from "../../Components/WesButton";
import styles from "./RecipientForm.module.scss";


export interface IProps {
    onSubmit: (recipient: IRecipient) => void;
    edit: IRecipient | null;
}


export default class RecipientForm extends React.Component<IProps> {
    static contextType = WesContext;
    res = (key: string, ...val: any[]) => this.context.resources.get(key, val);
    submitForm: any = null;

    validation = Yup.object().shape({
        name: Validations.personName(this.context),
        email: Validations.email(this.context),
        organization: Validations.reqString(this.context, 50)
    });

    render() {
        const { edit } = this.props;
        const fields: IFieldProps[] = [{
            name: "name",
            initialValue: edit?.name ?? "",
            label: this.res("RecipientName")
        }, {
            name: "email",
            type: "email",
            initialValue: edit?.email ?? "",
            label: this.res("RecipientEmail")
        }, {
            name: "organization",
            initialValue: edit?.organization ?? "",
            label: this.res("OrganizationInstitutionName"),
        }];

        return (
            <>
                <WesForm 
                    key={this.props.edit?.email || "new"}
                    validation={this.validation}
                    fields={fields}
                    onSubmit={this.onSubmit}
                    bindSubmit={e => this.bindSubmit(e)}
                />
                <div className={styles.saveButton}>
                    <WesButton 
                        text={this.res("Save")}
                        onClick={() => this.submitForm()}
                    />
                </div>
            </>
        );
    }


    bindSubmit = (form: () => void) => {
        this.submitForm = form;
    }

    onSubmit = (values: IRecipient) : void => {
        this.props.onSubmit(values);
    }
}
import React from "react";
import DataService from "../Services/DataService";
import { IOrder, OrderType } from "../Services/DataServiceModels";
import { WesContext } from "../Contexts/WesContext";
import FormatService from "../Services/FormatService";
import WesDataList, { IColumn } from "../Components/WesDataList";
import DashboardTitle from "../Components/DashboardTitle";
import Receipt from "./Receipt";
import Config from "../Assets/Config";

interface IState {
    loading: boolean;
    transactions: IOrder[];
    showReceipt: boolean;
    orderId: number;
}

export default class TransactionHistoryList extends React.Component<any, IState> {
    static contextType = WesContext;
    res = (key: string, ...val: any[]) => this.context.resources.get(key, val);

    state: IState = {
        loading: false,
        transactions: [],
        showReceipt: false,
        orderId: 0
    };

    async componentDidMount() {
        try  {
            this.setState({ loading: true });
            const result = await DataService.getTransactionHistory();
            this.setState({ 
                transactions: result,
                loading: false
            });
        }
        catch (ex) {
            console.log(ex);
        }
    }
    
    render() {
        const cols: IColumn[] = [{
            title: this.res("Date"),
            render: (item: IOrder) => FormatService.formatDate(item.date)
        }, {
            title: this.res("OrderType"),
            render: (item: IOrder) => this.res("OrderType" + OrderType[item.type])
        }, {
            title: this.res("OrderNumber"),
            render: (item: IOrder) => item.id
        }, {
            title: this.res("AmountPaid"),
            render: (item: IOrder) => `${Config.currency} $${item.amount}`
        }];

        return (
            <>
                <DashboardTitle
                    title={this.res("TransactionHistory")}
                    badge={this.state.transactions.length}
                    text={this.res("TransactionHistoryDescription")}
                />
                <WesDataList
                    data={this.state.transactions}
                    loading={this.state.loading}
                    columns={cols}
                />
                <Receipt 
                    show={this.state.showReceipt}
                    orderId={this.state.orderId}
                    onClose={() => this.setState({ 
                        showReceipt: false,
                        orderId: 0
                    })}
                />
            </>
        );
    }
}
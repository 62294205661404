import React from "react";
import styles from "./StepProgressBar.module.scss";

export interface IProps {
    step: number;
    steps: string[];
}

export default class StepProgressBar extends React.Component<IProps> {

    render() {
        const itemWidth = 100 / this.props.steps.length;
        const list = this.props.steps.map((title, index) => {
            let cls = "";             
            if ((index + 1) < this.props.step)
                cls = styles.active;
                
            if (index + 1 === this.props.step)
                cls += " " + styles.current;

            if (this.props.steps.length == 3)
                cls += " " + styles.step3;

            if (this.props.steps.length == 2)
                cls += " " + styles.step2;

            return (<li key={index} style={{ width: `${itemWidth}%` }} className={cls}>{title}</li>);
        })
        return (
            <div className={styles.container}>
                <ul className={styles.progressbar}>
                    {list}
                </ul>
            </div>
        );
    }
}
import React from 'react';
import WesBadge from '../Components/WesBadge';
import styles from './DashboardTitle.module.scss';
import { Stack } from 'react-bootstrap';

export interface IProps {
    title: string;
    badge: number;
    subtitle?: string;
    text?: string;
}

export default class DashboardTitle extends React.Component<IProps> {
    render() {
        return (
            <div className={styles.container}>
                <Stack direction="vertical">
                    <Stack direction="horizontal" gap={3} style={{ width: '100%' }}>
                        <div className={styles.dashBoardListHeader}>{this.props.title}</div>
                        <WesBadge count={this.props.badge} />
                        {this.props.children && <div className={styles.button}>{this.props.children}</div>}
                    </Stack>

                    {this.props.subtitle && (
                        <div className={`${styles.text} ${styles.subtitle}`}>{this.props.subtitle}</div>
                    )}
                    {this.props.text && (
                        <div className={`${styles.text} ${styles.subtext}`}>{this.props.text}</div>
                    )}
                </Stack>
            </div>
        );
    }
}
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styles from './FormHeader.module.scss';

export interface IProps {
    title: string;
    subtitle?: string;
    subtext?: string;
}

export default class FormHeader extends React.Component<IProps> {

    render() {
        return (
            <div className={styles.header}>
                <Container>
                    <Row><Col><span className={styles.title}>{this.props.title}</span></Col></Row>
                    
                    {this.props.subtitle && (
                        <Row>
                            <Col>
                                <span className={styles.subtitle}>{this.props.subtitle}</span>
                            </Col>
                        </Row>
                    )}
                    {this.props.subtext && (
                        <Row>
                            <Col>
                                <span className={styles.subtext}>{this.props.subtext}</span>
                            </Col>
                        </Row>
                    )}
                </Container>
            </div>
        )
    }
}
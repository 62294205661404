import React from "react";
import styles from "./ShareDocViewer.module.scss"
//import { Document, Page, pdfjs } from 'react-pdf';
//pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export interface IProps {
    location: any;
    history: any;
}

export interface IState {
    success: boolean;
    fileLink: string;
    pages: number;
}

export default class ShareDocViewer extends React.Component<IProps, IState> {
    state: IState = {
        success: false,
        fileLink: "",
        pages: 0
    };

    componentDidMount() {
        try {

            let accessKey = this.props.location.search.substring(1);
            // trim
            accessKey = accessKey.replace("key=", "");
            var fileSource = window.location.origin + "/reference/viewshare/" + accessKey;

            this.setState({ fileLink: fileSource });
            //document.addEventListener('contextmenu', (e) => {
            //    e.preventDefault();
            //});
        }
        catch (ex) {
            this.context.logging.error(ex);
        }
    }

    onDocLoadSuccess = ({ numPages }: any) => {
        console.log(numPages);
        this.setState({ pages: numPages });
    }

    render() {
        return (
            <div className={styles.wrapper} >
                <iframe src={this.state.fileLink + "#toolbar=0"} width="100%" height="750px">
                </iframe>
                
                {/*<div className={styles.embedCover}></div>*/}

                {/*<Document file={this.state.fileLink} onLoadSuccess={this.onDocLoadSuccess}>*/}
                {/*    {Array.from(new Array(this.state.pages), (el, index) => (*/}
                {/*        <Page key={`page_${index + 1}`} pageNumber={index + 1} />*/}
                {/*    ))}*/}
                {/*</Document>*/}

            </div>);
    }

}

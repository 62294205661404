import React from 'react';
import DataService from '../Services/DataService';
import { IPendingVerificationCredential } from '../Services/DataServiceModels';
import Row from 'react-bootstrap/Row';
import PendingActions from './Components/PendingActions';
import AddCredential from '../VerifyCredential/AddCredential';
import { WesContext } from '../Contexts/WesContext';
import DashboardTitle from '../Components/DashboardTitle';
import WesBadge from '../Components/WesBadge';
import WesDataList, { IColumn } from '../Components/WesDataList';
import { Container } from 'react-bootstrap';
import styles from './PendingVerificationList.module.scss';
import Config from '../Assets/Config';
import WesModal, { IButtonProps } from '../Components/WesModal';

interface IState {
    loading: boolean;
    data: IPendingVerificationCredential[];
    showReqDocs: boolean;
}

export default class PendingVerificationList extends React.Component<any, IState> {
    static contextType = WesContext;
    res = (key: string, ...val: any[]) => this.context.resources.get(key, val);
    resNested = (key: string) => this.context.resources.getNested(key);

    state: IState = {
        loading: false,
        data: [],
        showReqDocs: false
    };

    componentDidMount() {
        this.loadData();
    }

    render() {
        const cols: IColumn[] = [{
            title: this.res("DocumentTypeName"),
            render: (item: IPendingVerificationCredential) => item.certificate
        }, {
            title: this.res("OrderNumber"),
            render: (item: IPendingVerificationCredential) => item.orderNumber
        }, {
            title: this.res("Status"),
            render: (item: IPendingVerificationCredential) => {
                const state = this.getAlertCount(item) < 2 ? "InProgress" : "OnHold";
                return this.res("VerificationState" + state);
            }
        }, {
            title: this.res("Actions"),
            render: (item: IPendingVerificationCredential) => <PendingActions credential={item} onChange={() => this.loadData()} onReqDocsClick={() => this.setState({ showReqDocs: true })} />
        }, {
            title: this.res("Alerts"),
            render: (item: IPendingVerificationCredential) => {
                const count = this.getAlertCount(item);
                return <WesBadge alert={count > 0} count={count} />
            }
        }];

        const reqDocsClose: IButtonProps = {
            text: this.res("Close"),
            enabled: true,
            visible: true,
            onClick: () => {
                this.setState({ showReqDocs: false });
            }
        };

        return (
            <>
                <DashboardTitle
                    title={this.res("PendingVerification")}
                    badge={this.state.data.length}
                    text={this.res("VerificationPrice", Config.credentialVerificationPrice)}
                >
                    <AddCredential onSubmit={() => this.loadData()} />
                </DashboardTitle>

                <WesDataList
                    data={this.state.data}
                    loading={this.state.loading}
                    columns={cols}
                    noDataText={this.res("NoPendingVerifications")}
                    renderExpandRow={(item: IPendingVerificationCredential) => {
                        if (this.getAlertCount(item) === 0)
                            return (<div>{this.res("NoAlerts")}</div>);

                        let alerts = [];
                        if (item.isMissingDegreeCertificate) {
                            alerts.push(<Row className={`${styles.expanderRow} ${styles.actions}`}>{this.res("RequiredAction")}: {this.res("UploadDegreeCertificate")}</Row>);
                        }
                        if (item.isMissingTranscript) {
                            alerts.push(<Row className={`${styles.expanderRow} ${styles.actions}`}>{this.res("RequiredAction")}: {this.res("SendTranscript")}</Row>);
                        }
                        return (<Container>{alerts}</Container>);
                    }}
                />

                <WesModal show={this.state.showReqDocs} title={this.res("RequiredDocuments")} nextBtn={reqDocsClose}>
                    <div style={{ padding: "10px 20px" }}>
                        <div>
                            {this.resNested("RequiredDocumentsContent:Intro")}
                            <br />
                            <ul>
                                <li>{this.resNested("RequiredDocumentsContent:list1")}</li>
                                <li>{this.resNested("RequiredDocumentsContent:list2")}</li>
                            </ul>
                            {this.resNested("RequiredDocumentsContent:conclusion")}
                        </div>
                    </div>
                </WesModal>
            </>
        );

    }

    getAlertCount(item: IPendingVerificationCredential): number {
        let i = 0;
        if (item.isMissingDegreeCertificate) i++;
        if (item.isMissingTranscript) i++;
        return i;
    }

    async loadData() {
        try {
            this.setState({ loading: true });
            var data = await DataService.getMyPendingCredentials();
            this.setState({ data: data, loading: false });
        }
        catch (ex) {
            this.context.logging.error(ex);
            this.setState({ loading: false });
        }
    }

}